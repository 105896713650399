<home>
  <div class="container">
    <div class="jumbotron">
      <h1>M2M System Source</h1>
      <p>Administration area for the sims management system</p>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="thumbnail">
          <a href="/sims"><img src="/images/sims.png" alt="Sims"></a>
          <div class="caption">
          <h3 class="text-center">Sims Manager</h3>
          </div>
        </div>
      </div>
      <!--
      <div class="col-md-3">
        <div class="thumbnail">
          <a href="/accounts"><img src="/images/accounts.png" alt="Accounts"></a>
          <div class="caption">
          <h3 class="text-center">User Accounts</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="thumbnail">
          <a href="/providers"><img src="/images/providers.png" alt="Providers"></a>
          <div class="caption">
          <h3 class="text-center">Providers</h3>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="thumbnail">
          <a href="/usage"><img src="/images/usage.png" alt="Usage"></a>
          <div class="caption">
          <h3 class="text-center">Usage Statistics</h3>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>

  <style>
    .thumbnail:hover {
      background-color: #ecf0f1
    }
  </style>

</home>
