<maps-edit-members>
  <div class="panel panel-primary">
    <div class="panel-heading"></div>
    <div class="panel-body" if={this.map}>
      <div class="form-group">
        <select id="search-member" class="form-control input-sm" placeholder="search"></select>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Admin</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="parent-showing" each={member in map.members}>
            <td>{member._account.username}</td>
            <td>{member._account.email}</td>

            <td  class="text-center adjust-cell">
              <a if={member.admin} href="#" data-admin={member.admin} data-id={member._account._id} onclick={toggleAdminRights} nolink data-toggle="tooltip" title="Can view and edit the map. Click to change."><i class="fa fa-check-circle-o text-success"></i></a>
              <a if={!member.admin} href="#" data-admin={member.admin} data-id={member._account._id} onclick={toggleAdminRights} nolink data-toggle="tooltip" title="Only can view the map. Click to add admin rights."><i class="fa fa-ban text-danger"></i></a>
            </td>

            <td class="adjust-cell">
              <a href="/accounts/{member._account._id}/edit" target="_blank" class="btn btn-primary btn-xs children-hidden" title="Edit in a new window" data-toggle="tooltip">
                  <i class="fa fa-pencil"></i></a>
              <a href="#" nolink onclick={removeMember} data-id={member._account._id} class="btn btn-danger btn-xs children-hidden" data-toggle="tooltip" title="Remove member from this map">
                <i class="fa fa-trash"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="panel-body" if={!this.map}>
      <div class="alert alert-info">Loading map, please wait...</div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils')
    this.map = null

    this.initSelect2 = () => {
      window.$('#search-member').select2({
        theme: 'bootstrap',
        placeholder: 'Add Member',
        escapeMarkup: function (markup) {return markup},
        templateResult: formatRepo,
        templateSelection: formatRepoSelection,
        ajax: {
          url: window.__CONF.apiUrl + '/v1/accounts/members/',
          headers: {
            Authorization: 'Basic ' + window.Cookies.get('m2mss-login')
          },
          dataType: 'json',
          type: 'get',
          delay: 250,
          data: function (params) {
            if (params.term) {
              return {search: 'name:' + params.term}
            }
            return {}
          },
          processResults: (data, params) => {
            var select2Data = []
            var i = data.length

            while (i--) {
              var item = data[i]
              if (!this.memberIsAdded(item._id)) {
                select2Data.push({
                  id: item._id,
                  _id: item._id,
                  text: item.username,
                  name: item.username,
                  member: item
                })
              }
            }
            return {results: select2Data}
          },
          cache: true
        }
      })

      window.$('#search-member').on('select2:select', this.addSelectedMember)
    }

    /**
     * Verifica si el member ya existe en el array this.maps.members. En tal caso
     * no lo mostrará en el select2
     * @param  {String} memberId
     * @return {Boolean}
     */
    this.memberIsAdded = (memberId) => {
      let i = this.map.members.length
      while (i--) if (this.map.members[i]._id === memberId) return true
      return false
    }

    /**
     * Acción para el evento "click" al seleccionar algo en el selectbox.
     * Se realiza la petición ajax y al recibir el 200 OK se añade el member
     * al al array this.maps.member para hacer un update del tag
     *
     * @param  {Object} data Datos del evento click y otros detalles enviados
     *                       por select2, incluye la propiedad params.data.member
     */
    this.addSelectedMember = (data) => {
      const member = {
        _account: data.params.data.member,
        admin: false
      }

      utils.fetch({
        url: `${window.__CONF.apiUrl}/v1/iot-members/${this.map._id}`,
        data: member,
        type: 'post',
        update: false,
        callback: () => {
          this.map.members.push(member)
          this.update()
        },
        error: (xhr) => {
          window.swal('Error while adding a member to the map. Please try again.')
        }
      })()
    }

    this.toggleAdminRights = (e) => {
      if (!e) return // ¿por qué
      const $el = window.$(e.currentTarget)
      const memberId = $el.data('id')
      const url = `${window.__CONF.apiUrl}/v1/iot-members/${this.map._id}/member/${memberId}`
      utils.fetch({
        url: url,
        data: {admin: !$el.data('admin')},
        type: 'put',
        update: false,
        callback: (iot) => {
          this.map.members = iot.members
          this.update()
        },
        error: (xhr) => {
          window.swal('Error while adding a member to the map. Please try again.')
        }
      })()
    }

    this.removeMember = (e) => {
      if (!e) return  // por qué?
      const $el = window.$(e.currentTarget)
      const memberId = $el.data('id')
      const url = `${window.__CONF.apiUrl}/v1/iot-members/${this.map._id}/member/${memberId}`
      utils.fetch({
        url: url,
        type: 'delete',
        update: false,
        callback: (iot) => {
          $el.closest('tr').animate({opacity: 0}, 'fast', () => {
            this.map.members = iot.members
            this.update()
          })
        },
        error: (xhr) => {
          window.swal('Error while adding a member to the map. Please try again.')
        }
      })()
    }

    // hay que esperar a que el parent cargue los datos del mapa antes
    // de inicializar esta vista
    this.parent.on('map-loaded', () => {
      this.map = this.opts.map
      this.map.members = this.map.members || []
      this.update()
      this.initSelect2()
    })

    function formatRepo (item) {
      if (item.loading) return item.text
      return '<div>' + item.name + '</div>'
    }

    function formatRepoSelection (item) {
      return item.name || item.text
    }
  </script>
</maps-edit-members>
