<sims-user>

  <div class="container-fluid container-fluid-header">
    <h1>Sims</h1>
  </div>

  <div class="container-fluid">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="text-center" data-orderable="false">
            <awesome-checkbox data={opts}></awesome-checkbox>
          <th data-sort="desc">id</th>
          <th>iccid</th>
          <th>imsi</th>
          <th>status</th>
        </tr>
      </thead>
      <thead class="filters">
        <tr>
          <td></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search"></td>
          <td class="has-search"></td>
          <td class="has-search input-search-sm"></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={opts.items} class="parent-showing" data-iccid="{iccid}">
          <td class="text-center">
            <awesome-checkbox data={this}></awesome-checkbox>
          <td>{_id}</td>
          <td><a href="/sims/{iccid}/view"><strong>{iccid}<strong></a></td>
          <td>{imsi}</td>
          <td>{(status) ? status : 'unknow'}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <script>
    const utils = require('../utils/utils');
    const select = 'iccid,imsi,status,_provider,_account';
    const populate = '_provider:name;_account:username';

    const fetchOptions = {
      opts: opts,
      url: window.__CONF.apiUrl + '/v1/sims?limit=0&select='+select+'&populate=' + populate,
    }
    this.on('mount', utils.fetch(fetchOptions));

    const tableOptions = {
      order: [[3, 'asc']],
      "columns": [
        null,
        { "width": "50px" },
        null,
        null,
        { "width": "50px" }
      ]
    }
    this.on('updated', utils.setupDatatables(tableOptions));

    this.removeItem = (e) => {
      e.preventUpdate = true;
      if (e.altKey && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        const $el = $(e.currentTarget);
        const iccid = $el.closest('tr').data('iccid');

        utils.removeSim(iccid, function() {
          $('tr[data-iccid="'+iccid+'"]').fadeOut('slow', function() {
            $(this).remove();
          });
        });
      }
    }
  </script>
</sims-user>
