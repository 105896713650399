<device-status>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Device Status - Power ON/OFF</h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-6">
          <h4>Current Status</h4>
          <span if={status === null}>loading</span>
          <div if={status}>
            <span if={status.alive === false}>NOT CONNECTED</span>
            <span if={status.alive === true}>
              Connected to the network<br />
              <span class="label label-success" if={status.status === 1} title="The device is connected and enabled. Press button OFF to disable">power on</span>
              <span class="label label-danger" if={status.status === 0} title="The device is connected and disabled. Press button ON to enable">power off</span>
              <span class="label label-warning" if={status.status === -1} title="The device is connected to the gateway but status ON/OFF is unknow">unknow</span>
            </span>
          </div>
        </div>

        <div class="col-md-6">
          <h4>Change Status</h4>
          <button onclick={cmdOn} class="btn btn-success" type="button">ON</button>
          <button onclick={cmdOff} class="btn btn-danger" type="button">OFF</button>
        </div>
      </div>
    </div>
  </div>

  <div if={window.IS_ADMIN} class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Send commands</h3>
    </div>
    <div class="panel-body">
      <p class="lead">custom command | <small><i class="fa fa-ban"></i> stop manazas</small></p>
      <div if={window.IS_ADMIN} class="input-group" id="custom-cfg">
        <input type="text" class="form-control" id="cmd-input">
        <span class="input-group-btn">
          <button class="btn btn-default" type="button" onclick={customCmd}>Send</button>
        </span>
      </div>
      <div class="row mt-1">
        <div class="col-md-6">
          <awesome-checkbox labeltext={"Transmit now"} data={ {_id: 'transmit-now'} }></awesome-checkbox>
        </div>
        <div class="col-md-6">
          <awesome-checkbox labeltext={"Save if fails (shadow)"} data={ {_id: 'save-shadow'} }></awesome-checkbox>
        </div>
      </div>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'
    import {transmitCmd} from './transmit-cmd'

    this.status = null
    this.device = this.opts.device || this.parent.device || null

    const loadStatus = () => {
      if (!this.device) console.log('no device (this.device)')
      fetch({
        endpoint: `/v1/devices/isalive/${this.device._id}`,
        update: false,
        callback: (data) => {
          this.status = data
          this.update()
        },
        error: (err) => console.log(err)
      })()
    }

    this.cmdOn = (e) => {
      e.preventUpdate = true

      const cmd = {
        cmd: `#IO6|ON$`,
        cache: 1,
        save: 0,
        transmiNow: 0
      }

      transmitCmd(this.device._id, cmd, (err, sent) => {
        if (sent) {
          this.status.alive = true
          this.status.status = 1
        } else {
          this.status.alive = false
          this.status.status = -1
        }

        this.update()
      })
    }

    this.cmdOff = (e) => {
      e.preventUpdate = true
      const cmd = {
        cmd: `#IO6|OFF$`,
        cache: 1,
        save: 0,
        transmiNow: 0
      }
      transmitCmd(this.device._id, cmd, (err, sent) => {
        if (sent) {
          this.status.alive = true
          this.status.status = 0
        } else {
          this.status.alive = false
          this.status.status = -1
        }

        this.update()
      })
    }

    this.customCmd = (e) => {
      e.preventUpdate = true
      const cmd = {
        cmd: `#${$('#cmd-input').val()}$`,
        cache: 0,
        save: ($('#check-save-shadow').is(':checked')) ? 1 : 0,
        transmitNow: ($('#check-transmit-now').is(':checked')) ? 1 : 0
      }
      transmitCmd(this.device._id, cmd)
    }

    this.cmdAck = (data) => {
      console.log('cmdAck', data)
    }

    this.on('mount', () => {
      loadStatus()
      window.emitter.on('ack', this.cmdAck)
    })

    this.on('unmount', () => {
      window.emitter.off('ack', this.cmdAck)
    })

  </script>

</device-status>
