<maps-edit-layers>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <div class="btn-group pull-right">
        <a href="#" class="btn btn-link btn-xs">
          <span class="fa fa-map-marker"></span>
          Add Waypoints</a>
        <a href="#" class="btn btn-link btn-xs">
          <span class="fa fa-map-marker"></span>
          Add Route</a>
      </div>
      <h3 class="panel-title">Manage Layers</h3>
    </div>
    <div class="panel-body">Map Layers</div>
  </div>
</maps-edit-layers>
