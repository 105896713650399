<providers-remove>

  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/providers" class="btn btn-default" data-toggle="tooltip" title="Go back to providers list">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1>Remove Provider</h1>
  </div>

  <div class="container-fluid">
    <div if={!opts.removed} class="row">
      <div class="col-md-4 col-md-offset-4">
        <div id="provider-data" class="thumbnail control-group">
          <p>loading provider data for preview...</p>
        </div>
      </div>

      <div class="col-md-4 col-md-offset-4">
        <button class="btn btn-danger btn-block btn-lg" onclick={remove}>Remove provider<br />ID: {opts._id}</button>
      </div>
    </div>

    <div if={opts.removed} class="row">
      <div class="col-md-4 col-md-offset-4">
        <div class="alert alert-success fade in">
          Ok, bye bye provider. Work done!<br />
          Return to <a href="/providers" class="alert-link">providers list</a></div>
      </div>
    </div>
  </div>

  <script>
    const that = this
    const utils = require('../utils/utils')
    import JSONFormatter from 'json-formatter-js'

    this.remove = (e) => {
      var options = {
        module: 'providers',
        id: opts._id,
        confirm: !e.ctrlKey
      }
      utils.removeObject(options, function() {
        opts.removed = true
        that.update()
      })
    }

    const fetchOptions = {
      type: 'get',
      update: false,
      url: window.__CONF.apiUrl + '/v1/providers/' + opts._id + '?select=**&populate=_provider,_account',
      callback: function(data) {
        var dataString = ''
        try {
          const jsonFormatter = new JSONFormatter(data)
          dataString = jsonFormatter.render()
        } catch(e) {
          dataString = 'Sorry, error loading provider data...'
        }
        jQuery('#provider-data').html(dataString)
      }
    }

    utils.fetch(fetchOptions)()

  </script>
</providers-remove>
