<providers>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/providers/0/add" class="btn btn-default">
        <span class="fa fa-plus-sign"></span> Add Provider</a>
    </div>
    <h1>Providers</h1>
  </div>

  <div class="container-fluid">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th data-orderable="false">
            <awesome-checkbox data={opts}></awesome-checkbox>
          </th>
          <th data-sort="desc">id</th>
          <th>name</th>
          <th>enabled</th>
          <th>username</th>
          <th>cert</th>
          <th>apiurl</th>
          <th data-orderable="false"></th>
        </tr>
      </thead>
      <thead class="filters">
        <tr>
          <td></td>
          <td class="has-search"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search"></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={opts.items} class="parent-showing">
          <td class="text-center">
            <awesome-checkbox data={this}></awesome-checkbox>
          </td>
          <td><strong><a href="/providers/{_id}/edit">{_id}</a></strong></td>
          <td>{name}</td>
          <td>{(enabled) ? 'yes' : 'no'}</td>
          <td>{auth.username}</td>
          <td>{(auth.cert) ? 'yes' : 'no'}</td>
          <td class="text-center">
            <button
              type="button"
              class="btn btn-default btn-xs btn-copy"
              data-clipboard-text="{auth.apiurl}"
              data-toggle="tooltip"
              data-placement="top"
              title="Copy to clipboard">
              <span class="fa fa-copy"></span></button>
          </td>
          <td class="text-center">
            <div class="btn-group children-hidden">
              <a href="/providers/{_id}/remove" class="btn btn-danger btn-xs">
                <span class="fa fa-remove"></span></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <script>
    const utils = require('../utils/utils')
    const that = this
    const tableOptions = {order: [[3, 'asc']]}
    const fetchOptions = {
      opts: opts,
      url: window.__CONF.apiUrl + '/v1/providers?select=*'
    }

    this.on('mount', utils.fetch(fetchOptions))
    this.on('updated', utils.setupDatatables(tableOptions))
  </script>
</providers>
