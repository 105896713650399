<device-metadata>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Device metadata</h3>
    </div>
    <div class="panel-body">
      <form data-toggle="validator" class="form" id="form-device-metadata" onsubmit={save}>
        <div class="form-group col-md-6">
          <label for="deviceId">ID/IMEI</label>
          <input class="form-control" id="deviceId" value="{device._id}" type="text" required disabled>
        </div>

        <div class="form-group col-md-6">
          <label for="device-name">Internal name</label>
          <input class="form-control" id="device-name" value="{device.name}" type="text" required disabled={!window.IS_ADMIN}>
          <div class="help-block with-errors"></div>
        </div>

        <div if={window.IS_ADMIN} class="form-group col-md-6">
          <label for="device-fversion">F. Version</label>
          <input class="form-control" id="device-fversion" value="{device.status.version || '0.1'}" type="text" required disabled={!window.IS_ADMIN}>
          <div class="help-block with-errors"></div>
        </div>

        <div class="form-group col-md-6">
          <label for="device-customName">Customer Name</label>
          <input class="form-control" id="device-customName" value="{device.customName}" type="text">
          <div class="help-block with-errors"></div>
        </div>

        <div class="form-group col-md-6">
          <label for="device-group">Group</label>
          <input class="form-control" id="device-group" value="{device.status.group || ''}" type="text">
        </div>

        <div class="form-group col-md-6">
          <label for="device-color">Color</label>
          <input class="form-control" id="device-color" value="{device.color}" type="color">
          <div class="help-block with-errors"></div>
        </div>

        <div if={window.IS_ADMIN} class="form-group col-md-6">
          <label for="device-freeze">Freeze</label>
          <select id="device-freeze" class="form-control">
            <option value="0" selected={device.freeze}>No</option>
            <option value="1" selected={device.freeze}>Yes</option>
          </select>
        </div>

        <div if={window.IS_ADMIN} class="form-group col-md-6">
          <label for="device-account">Account</label>
          <select class="form-control" id="device-account">
            <option each={account in accounts} value={account._id} selected={account._id === device._account ? 'selected' : ''}>{account.username}</option>
          </select>
          <div class="help-block with-errors"></div>
        </div>

        <div class="form-group col-md-12">
          <button class="btn btn-primary" type="submit" id="btn-save">Save</button>
        </div>

      </form>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'

    this.device = this.parent.device
    this.accounts = []

    this.getAccounts = () => {
      fetch({
        endpoint: '/v1/accounts',
        update: false,
        callback: (data) => {
          this.accounts = data || []
          this.update()
        }
      })()
    }

    this.save = (e) => {
      e.preventDefault()
      e.preventUpdate = true
      const $button = $('#btn-save')

      if ($button.hasClass('disabled') || $button.prop('disabled')) {
        return window.swal('Form errors', 'The form has errors. Please fix it.', 'error')
      }

      const deviceData = {}
      deviceData.color = this.$root.find('#device-color').val()
      deviceData.customName = this.$root.find('#device-customName').val()
      deviceData.status = {}
      deviceData.status.group = this.$root.find('#device-group').val()

      if (window.IS_ADMIN) {
        deviceData.freeze = (this.$root.find('#device-freeze').val() === "0" ? false : true)
        deviceData.name = this.$root.find('#device-name').val()
        deviceData.status.version = this.$root.find('#device-fversion').val()
        deviceData._account = this.$root.find('#device-account').val()
      }

      this.parent.save(deviceData)
    }

    this.on('mount', () => {
      window.jQuery('#form-device-metadata').validator()
      this.$root = $(this.root)
      this.getAccounts()
    })
  </script>
</device-metadata>
