<sims-edit>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <button class="btn btn-primary" onclick={save}>
        <span class="fa fa-save"></span> Save</button>
      <a href="/sims" class="btn btn-default" onclick={cancel}>
        <span class="fa fa-remove-circle"></span> Cancel</a>
    </div>
    <h1>Edit sim</h1>
  </div>

  <div class="container-fluid">

    <form data-toggle="validator" class="form">
      <div class="row">
        <div class="col-md-3">

          <div class="form-group">
            <label for="iccid">iccid</label>
            <input class="form-control" id="iccid" disabled="disabled" value="{opts.iccid}"
              type="text"
              required>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="msisdn">msisdn</label>
            <input type="text" class="form-control" id="msisdn" disabled="disabled" value="{opts.msisdn}">
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="imsi">imsi</label>
            <input type="text" class="form-control" id="imsi" disabled="disabled" value="{opts.imsi}">
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <div class="form-group">
              <label for="imei">imei</label>
              <input type="text" class="form-control" id="imei" disabled="disabled" value="{opts.imei}">
              <div class="help-block with-errors"></div>
            </div>
          </div>

        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="_provider">provider</label>
            <select class="form-control" id="_provider" style="width: 100%">
              <option
                each={provider in opts.providers}
                value={provider._id}
                selected={parent.opts._provider === provider._id}>{provider.name}</option>
            </select>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="_account">account</label>
            <select class="form-control" id="_account">
              <option
                each={account in opts.accounts}
                value={account._id}
                selected={parent.opts._account === account._id}>{account.username}</option>
            </select>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="freeze">freezed</label>
            <select class="form-control" id="freeze">
              <option value="0" selected={opts.freeze}>No</option>
              <option value="1" selected={opts.freeze}>Yes</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Device</label>
                <div if={opts.device}>
                  <p>{opts.device.name} [<a href="/devices/{opts.device._id}/edit">edit</a>]</p>
                </div>

                <div if={!opts.device}>
                  <p class="text-danger">This sim doesn't have a devices associated.</p>
                  <p class="text-muted">Please, to assign this sim to a device use the
                    <a href="/devices/">devices management area</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Sim status</label>
                <p>Current status: <span class="label label-{opts.status === 'active' ? 'success' : 'warning'}">{opts.status}</span></p>
                <div>
                  <button type="button" class="btn btn-primary" onclick={setSimActive}>
                    set active
                    <span if={opts.status === 'active'}>(forze)</span>
                  </button>
                  <button type="button" class="btn btn-warning" onclick={setSimInactive}>
                    set inactive
                    <span if={opts.status === 'inactive'}>(forze)</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Sim details</label>
                <div>
                  <button class="btn btn-default" onclick={loadRawData}>Load Raw Data</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <sims-details data={opts}></sims-details>

  <script>
    var that = this;
    var utils = require('../utils/utils');
    const simDetails = require('../sims-admin/sims-details.tag')

    $('form').validator();
    var hasChanged = false;

    this.on('mount', function() {
      // Tenemos que obtener los datos de la sim, ademas es necesario obtener
      // todos los accounts y providers para mostrar en el desplegable.
      async.parallel({
        sim: function(callback) {
          var options = {
            url: window.__CONF.apiUrl + '/v1/sims/' + opts._id + '?select=*',
            update: false,
            callback: function(sim) {
              console.log('sim', sim)
              callback(null, sim)
            }
          }
          utils.fetch(options)()
        },

        // --
        providers: function(callback) {
          var options = {
            url: window.__CONF.apiUrl + '/v1/providers/?select=name',
            update: false,
            callback: function(providers) {
              callback(null, providers)
            }
          }
          utils.fetch(options)()
        },

        // --
        devices: function(callback) {
          var options = {
            url: window.__CONF.apiUrl + '/v1/devices/?select=name,_sim',
            update: false,
            callback: function(devices) {
              callback(null, devices)
            }
          }
          utils.fetch(options)()
        },

        // --
        accounts: function(callback) {
          var fetchAccountsOptions = {
            url: window.__CONF.apiUrl + '/v1/accounts/?select=username',
            update: false,
            callback: function(accounts) {
              callback(null, accounts)
            }
          }
          utils.fetch(fetchAccountsOptions)()
        }

      }, function(err, results) {
        if (err) console.log('fuck!', err)
        that.opts = results.sim
        that.opts.device = false

        that.opts.accounts = results.accounts
        that.opts.devices = results.devices
        that.opts.providers = results.providers

        that.opts.devices.forEach((device) => {
          if (device._sim === that.opts._id) {
            that.opts.device = device
          }
        })

        // --
        that.update()

        // --
        $('#_provider').val(results.sim._provider).trigger('change')

        // --
        that.changesSpy()
      })
    });

    this.save = () => {
      var data = this.getFormSimData()

      // si no tenemos data es porque se ha producido algún error de validación
      // en el método getFormSimData(). El usuario ya habrá sido avisado del
      // error, por lo que nada más que hacer aquí, salimos.
      if (!data) return

      var options = {
        url: window.__CONF.apiUrl + '/v1/sims/' + opts._id,
        update: false,
        data: data,
        type: 'PUT',
        callback: function(providers) {
          sweetAlert('Sim updated')
        },
        error: function() {
          sweetAlert('Oops!', 'Error, try again', 'error')
        }
      }
      utils.fetch(options)()
    }

    this.cancel = (e) => {
      if (hasChanged) {
        e.preventDefault()
        window.swal({
          title: 'Are you sure?',
          text: 'May be you have unsaved changes!',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Ok, keep editing',
          confirmButtonClass: 'btn-danger',
          confirmButtonText: 'Yes, go back',
          closeOnConfirm: false
        },
        function() {
          document.location.href = '/sims'
        })
      }
    }

    this.getFormSimData = () => {
      var data = {
        iccid: window.$('#iccid').val(),
        imsi: window.$('#imsi').val(),
        msisdn: window.$('#msisdn').val(),
        freeze: window.$('#freeze').val() ? true : false,
        _account: window.$('#_account').val(),
        _provider: window.$('#_provider').val(),
        _device: window.$('#_device').val()
      }

      return data
    }

    /**
     * Habilita comprobación de cambios en campos para alertar cuando se pulsa
     * el botón "cancel". Se utiliza para alerta al participante de que va a
     * abandonar la pagina sin guardar.
     */
    this.changesSpy = () => {
      $(this.root).one('keyup change', 'input, select, textarea', function() {
        hasChanged = true
      })
    }

    this.loadRawData = (e) => {
      e.preventUpdate = true
      e.preventDefault()
      this.trigger('sim-details', this.opts)
    }

    this.setStatus = (status) => {
      var options = {
        url: `https://api.m2msystemsource.com/v1/sims/${this.opts.iccid}/${status}`,
        update: false,
        type: 'put',
        callback: (result) => {
          window.swal('Sim status changed', 'The sim has status has been changed to: ' + status, 'success')
          let newStatus = 'active'
          if (status === 'deactivate') newStatus = 'inactive'
          this.opts.status = newStatus
          this.update()
        }
      }
      utils.fetch(options)()
    }

    this.setSimActive = (e) => {
      e.preventUpdate = true
      e.preventDefault()
      // realizamos la petición al servidor en producción, se requiere acceso
      // a la api del proveedor
      return this.setStatus('activate')
    }

    this.setSimInactive = (e) => {
      e.preventUpdate = true
      e.preventDefault()
      // realizamos la petición al servidor en producción, se requiere acceso
      // a la api del proveedor
      return this.setStatus('deactivate')
    }
  </script>

  <style>
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .select2-container {
      width: 100% !important;
    }
  </style>

</sims-edit>
