<demo-eskaymob>
<!--
  <div class="container-fluid container-fluid-header">
    <div class="row">
      <div class="col-md-12">
        <h1>Demo Eskay Mob</h1>
      </div>
    </div>
  </div>

  <div if={devices === null} class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p>Loading...</p>
      </div>
    </div>
  </div>

  <div if={devices !== null} class="container-fluid">
    <div if={devices.length === 0} class="row">
      <div class="col-md-12">
        <p>No hay dispositivos</p>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row" if={devices.length > 0}>
        <div class="col-md-3" each={device in devices}>
          <div class="panel panel-default">
            <div class="panel-heading">{device.name} ({device._id})</div>
            <div class="panel-body">
              <button type="button" onclick={cmdOn} class="btn btn-success">ON</button>
              <button type="button" onclick={cmdOn} class="btn btn-danger">OFF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
-->

  <script>
    import {fetch} from '../utils/utils'
    this.devices = null
    this.cmdTimeout = null

    this.cmdOn = (e) => {
      e.preventDefault()
      const cmd = `#IO6|ON$`
      const imei = e.item.device._id
      transmit(imei, cmd)
    }

    this.cmdOff = (e) => {
      e.preventDefault()
      const cmd = `#IO6|OFF$`
      const imei = e.item.device._id
      transmit(imei, cmd)
    }

    const loadDevices = () => {
      fetch({
        endpoint: `/v1/devices?select=**`,
        update: false,
        callback: (data) => {
          this.devices = data
          this.update()
          enableIo()
        }
      })()
    }

    const transmit = (deviceId, cmd, _id) => {
      _id = _id || Math.floor(Math.random() * (999999 - 100000) + 100000)

      window.socket.emit('gwtcp2/transmitCmd', {_id, deviceId, cmd})

      swal({
        title: 'Command Transmission',
        text: `Please wait, we are contacting the device. This may take several seconds. The transmission ID is ` + _id,
        showConfirmButton: false,
        allowEscapeKey: false
      })

      this.cmdTimeout = setTimeout(() => {
        swal.close()
        setTimeout(() => ackFailed(), 100)
      }, 10000)

    }

    const enableIo = () => {
      this.devices.forEach((device) => {
        window.socket.on('gwtcp2/ack/' + device._id, (data) => receiveAck(data))
      })
    }

    const disableIo = () => {
      window.socket.off('gwtcp2/ack/' + device._id, receiveAck)
    }

    const receiveAck = (data) => {
      clearInterval(this.cmdTimeout)
      this.cmdTimeout = null
      swal.close()

      setTimeout(() => {
        swal({
          title: 'Command sent!',
          type: 'success',
          timer: 2000,
          showConfirmButton: false,
          allowEscapeKey: false
        })
      }, 500)
    }

    const ackFailed = () => {
      swal({
        title: 'Command failed!',
        text: 'Please try again',
        type: 'error',
        timer: 2000,
        showConfirmButton: false,
        allowEscapeKey: false
      })
    }

    this.on('mount', () => loadDevices())
    this.on('unmount', () => disableIo())
  </script>
</demo-eskaymob>
