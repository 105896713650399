<maps-edit-categories>
  <div class="pull-right">
    <button type="button" class="btn btn-link btn-sm"
      title="Map Categories"
      data-toggle="popover"
      data-placement="left"
      data-content="With categories you can organize your devices and layers by groups. After add a new category this will bew available on devices and layers sections of this page.">
      <span class="fa fa-info-circle"></span>
    </button>
  </div>

  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Categories</h3>
    </div>
    <div class="panel-body">
      <div class="form-group">
        <div class="input-group">
          <input type="text" class="form-control input-sm" id="input-add-category" placeholder="Category name">
          <span class="input-group-btn">
            <button class="btn btn-default btn-sm" type="button" onCLick={add}>Create</button>
          </span>
        </div>
      </div>
      <table class="table table-bordered table-condensed table-hover">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Devices Count</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="parent-showing" each={opts.map._categories}>
            <td>{name}</td>
            <td>{_devices.length}</td>
            <td class="adjust-cell">
              <button type="button" class="btn btn-success btn-xs children-hidden" data-id={_id} onClick={openManage}>
                <span class="fa fa-cog"></span></button>
              <button type="button" class="btn btn-danger btn-xs children-hidden" data-id={_id} onClick={remove}>
                <span class="fa fa-remove"></span></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <maps-manage-categories map={opts.map}></maps-manage-categories>

  <script>
    const that = this
    const utils = require('../utils/utils')
    let $search = null

    this.on('update', () => {
      $search = window.$('#input-add-category')
    })

    this.on('category-update', () => {
      that.parent.trigger('category-update')
    })

    this.add = (e) => {
      if (e && e.keyCode === 13) {
        e.preventDefault()
      } else if (e && e.keyCode) {
        return
      }
      const categoryName = $search.val()
      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot/' + this.opts.map._id + '/category',
        type: 'post',
        update: false,
        data: {
          categoryName: categoryName
        },
        callback: (data) => {
          $search.val('')
          that.opts.map._categories = data._categories
          that.update()
          that.parent.update()
        }
      }

      utils.fetch(fetchOptions)()
    }

    this.remove = (e) => {
      const categoryId = $(e.currentTarget).data('id')
      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot/' + this.opts.map._id + '/category',
        type: 'delete',
        update: false,
        data: {
          _category: categoryId
        },
        callback: function(data) {
          that.opts.map._categories = data._categories
          that.update()
          that.parent.update()
        }
      }
      utils.fetch(fetchOptions)()
    }

    this.openManage = (e) => {
      const $cat = window.$(e.currentTarget)
      this.tags['maps-manage-categories'].open($cat.data('id'))
    }

  </script>
</maps-edit-categories>
