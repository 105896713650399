<devices-user>
  <div class="container-fluid container-fluid-header">
    <div if={window.IS_ADMIN} class="btn-group pull-right">
      <a href="/devices/0/add" class="btn btn-default">
        <span class="fa fa-plus"></span> Create Device</a>
    </div>
    <h1>Devices</h1>
  </div>

  <div class="container-fluid">
    <table class="table table-bordered table-hover table-condensed small" if={devices.length}>
      <thead>
        <tr>
          <th if={window.IS_ADMIN}></th>
          <th if={window.IS_ADMIN} data-sort="desc" style="width: 120px">imei</th>
          <th>id</th>
          <th>Name</th>
          <th if={window.IS_ADMIN}>Group</th>
          <th>GPS Time</th>
          <th>Server Time</th>
          <th>Battery Int.</th>
          <th>Battery Ext.</th>
          <th>Vsys</th>
          <th>Loc.</th>
          <th>HDOP</th>
          <th>Sats.</th>
          <th>GSM</th>
          <th if={window.IS_ADMIN}><acronym title="Firmware">F.<acronym> Version</th>
          <th if={window.IS_ADMIN}>sticker</th>
          <th if={window.IS_ADMIN}>Sim</th>
          <th if={window.IS_ADMIN}>Owner</th>
        </tr>
        <tr class="filters">
          <td if={window.IS_ADMIN}></td>
          <td if={window.IS_ADMIN} class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search IMEI" /></td>
          <td class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search ID" /></td>
          <td class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search Name" /></td>
          <td if={window.IS_ADMIN} class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search Group" /></td>
          <td></td>
          <td></td>
          <td class="has-search input-search-sm"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td if={window.IS_ADMIN}></td>
          <td if={window.IS_ADMIN}></td>
          <td if={window.IS_ADMIN} class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search Sim" /></td>
          <td if={window.IS_ADMIN} class="has-search input-search-sm">
            <input class="form-control input-sm" type="text" placeholder="Search Owner" /></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={device in devices} class="parent-showing devices-list" data-id="{device._id}">
          <td if={window.IS_ADMIN}>
            <awesome-checkbox data={device}></awesome-checkbox>
          </td>
          <td if={window.IS_ADMIN}>{device._id}</td>
          <td name="name1" id="name2" data-dt-row="name3">
            <a if={!window.IS_ADMIN} href="/devices/{device._id}/edit" class="btn btn-link btn-xs">
              <i class="fa fa-edit"></i>
              {device.name}
            </a>
            <a if={window.IS_ADMIN} href="/device/{device._id}" class="btn btn-link btn-xs">
              <i class="fa fa-edit"></i>
              {device.name}
            </a>
            <a href="/devices/{device._id}/remove" if={window.IS_ADMIN} class="btn btn-link btn-xs children-hidden">
              <i class="fa fa-close"></i>
            </a>
          </td>
          <td>{device.customName}</td>
          <td if={window.IS_ADMIN}>{device.status.group || ''}</td>
          <td class="g" data-order={device.tracking.gpstime}>
            <span title={device.time.gpsDate} data-toggle="tooltip" class="text-{device.time.gpsStyle}">
              {device.time.gpsFormat}</span>
          </td>
          <td class="s" data-order={device.time.srv}>
            <span title={device.time.srvDate} data-toggle="tooltip" class="text-{device.time.srvStyle}">
              {device.time.srvFormat}</span>
          </td>
          <td data-order={device.tracking.data.battery || "0"}><span class="text-{device.styles.batt}">{device.tracking.data.battery}mv</span></td>
          <td data-order={device.tracking.data.extbattery || device.tracking.data.extbatt|| "0"}>{device.tracking.data.extbattery || device.tracking.data.extbatt|| "0"}mv</td>
          <td data-order={device.tracking.data.vsys || "0"}>{device.tracking.data.vsys || -1}mv</td>

          <td>
            <a if={device.tracking.data.loc} href="https://www.google.com/maps/place/{device.tracking.data.loc[1]},{device.tracking.data.loc[0]}" target="_blank">
              {device.tracking.data.loc[1]}, {device.tracking.data.loc[0]}</a>
          </td>
          <td data-order={device.tracking.data.gps || "0"}>{device.tracking.data.gps || ''}</td>
          <td  data-order={device.tracking.data.sats || "0"}>{device.tracking.data.sats || ''}</td>
          <td  data-order={device.tracking.data.gsm || "0"}>{device.tracking.data.gsm || ''}</td>

          <td if={window.IS_ADMIN}>{device.status.version}</td>
          <td if={window.IS_ADMIN}><a href="/sticker/{device._id}" target="_blank">Sticker</a></td>
          <td if={window.IS_ADMIN}><a href="/sims/{device._sim.iccid}/edit">{device._sim.iccid}</a></td>
          <td if={window.IS_ADMIN}><a href="/accounts/{device._account._id}/edit" if={device._account}>{device._account.username}</a></td>
        </tr>
      </tbody>
    </table>

    <div class="text-center" if={devices === 0}>
      <td colspan="7" class="text-center"><p class="lead">
        Your devices list is empty.</p></td>
    </div>

    <div class="text-center" if={devices.length === 0}>
      <td colspan="7" class="text-center"><p class="lead">
        Loading devices...</p></td>
    </div>

    <div class="hidden">
      <div class="btn-group" if={window.IS_ADMIN} id="btn-cog-button">
        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-cog"></i> <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li><strong>For Selected elements:</strong></li>
          <li><a href="#" id="open-set-group">Set group</a></li>
          <li><a href="#" id="change-group">Change owner</a></li>
          <li role="separator" class="divider"></li>
          <li><strong>Other options:</strong></li>
          <li><a href="#"  id="table-clear-filters">Clear table filters</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="modal fade" id="groups-modal" tabindex="-1" role="dialog" aria-labelledby="groups-modal-title">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="groups-modal-title">Set group for devices</h4>
        </div>
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label for="grup-name">Group Name</label>
              <input type="text" class="form-control" id="text-group-name">
            </div>
          </div>
          <p>Total devices: <span id="selected-total-devices"></span></p>
          <div id="selected-devices-list"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" onclick={setGroup}>Set Name</button>
        </div>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils')
    const tableOrder = window.IS_ADMIN ? 4 : 3
    let colIndex

    this.table = null
    this.devices = []
    this.selectedDevices = []
    window.devices = this

    this.loadDevices = () => {
      let endpoint = '/v1/devices?limit=0&select=name,tracking,sensing,customName,status'
      if (window.IS_ADMIN) {
        endpoint = '/v1/devices?limit=0&select=name,_sim,tracking,sensing,_account,customName&populate=_sim:iccid;_account:username'
      }

      utils.fetch({
        endpoint: endpoint,
        update: false,
        callback: (devices) => {
          this.devices = (devices) ? devices : 0
          this.parseDevicesAndUpdate()
          this.table = utils.setupDatatables(tableOptions)(this)
          this.setupCogButton()
        }
      })()
    }

    this.socketIoData = (data) => {
      let len = this.devices.length
      while (len--) {
        let device = this.devices[len]
        if (device._id === data._device) {
          this.updateDevice(device, data)
          return
        }
      }
    }

    this.updateDevice = (device, data) => {
      let deviceId = device._id
      let rowIndex = window.jQuery('tr[data-id="'+deviceId+'"]').index()
      let row = window.jQuery('tr[data-id="'+deviceId+'"]').get()
      if (rowIndex <= 0) return

      // buscamos el indice de la fila en dataTables. Los indices de dataTable y
      // la tabla en el DOM no corresponden debido a los métodos sort/search de
      // dataTables que modifican su indice de filas.
      const rowData = this.table.row(row).data() || null
      if (!rowData) return console.log('[updateDevice] no rowData')

      // servertime
      device.tracking.servertime = data.servertime
      console.log('device.tracking.servertime', device.tracking.servertime)
      let timeFrom = window.moment(data.servertime).fromNow(true)
      let timeymd = window.moment(data.servertime).format('YY-MM-DD HH:mm:ss')
      let timediff = Date.now() - data.servertime
      let timestyle = (timediff < 12000) ? 'success' : (timediff < 240000) ? 'warning' : 'danger'
      rowData[colIndex.stime]["@data-order"] = data.servertime
      rowData[colIndex.stime].display = `<span data-toggle="tooltip" title="${timeymd}" class="text-${timestyle}"> ${timeFrom}</span>`

      // battery, extbattery y vsys (en este orden)
      rowData[colIndex.vbat]["@data-order"] = data.data.battery
      rowData[colIndex.vbat].display = data.data.battery + 'mv'
      device.tracking.data.battery = data.data.battery

      let extbattery = data.data.extbattery || -1
      rowData[colIndex.vin]["@data-order"] = extbattery
      rowData[colIndex.vin].display = extbattery + 'mv'
      device.tracking.data.extbattery = extbattery

      let vsys = data.data.vsys || -1;
      rowData[colIndex.vsys]["@data-order"] = vsys || -1
      rowData[colIndex.vsys].display = vsys + 'mv'
      device.tracking.data.vsys = vsys

      let gpstime = parseInt(data.gpstime, 10) || parseInt(device.gpstime, 10)

      if (gpstime) {
        device.tracking.gpstime = gpstime
        timeFrom = window.moment(gpstime).fromNow(true)
        timeymd = window.moment(gpstime).format('YY-MM-DD HH:mm:ss')
        timediff = Date.now() - gpstime
        timestyle = (timediff < 60000) ? 'success' : (timediff < 240000) ? 'warning' : 'danger'
        rowData[colIndex.gtime]["@data-order"] = gpstime
        rowData[colIndex.gtime].display = `<span data-toggle="tooltip" title="${timeymd}" class="text-${timestyle}"> ${timeFrom}</span>`
      }

      this.table.row(row).data(rowData)
      this.table.row().invalidate().draw()
    }

    this.parseDevicesAndUpdate = () => {
      const now = Date.now()

      this.devices.forEach((device) => {
        device._sim = device._sim || {}
        device.tracking = device.tracking || {}
        device.sensing = device.sensing || {}
        device.tracking.data = device.tracking.data || {}
        device.time = {
          gps: 0,
          gpsFormat: 0,
          gpsStyle: 'danger',
          srv: 0,
          srvFormat: 0,
          srvStyle: 'danger'
        }

        let timediff = 0

        // server time
        timediff = now - device.tracking.servertime

        if (device.sensing.time) {
          device.time.srv = device.sensing.time
          device.time.srvFormat = (device.sensing.time)
            ? window.moment(device.sensing.time).fromNow(true)
            : '-'
          device.time.srvDate = window.moment(device.sensing.time).utc().format('YY-MM-DD HH:mm:ss')
        } else if (device.tracking.servertime) {
          device.time.srv = device.tracking.servertime
          device.time.srvFormat = window.moment(device.tracking.servertime).fromNow(true)
          device.time.srvDate = window.moment(device.tracking.servertime).utc().format('YY-MM-DD HH:mm:ss')
        }

        device.time.srvStyle = (timediff < 120000) ? 'success' : (timediff < 240000) ? 'warning' : 'danger'

        if (device.tracking.data.loc) {
          timediff = now - device.tracking.gpstime || 99999999
          device.time.gpsDate = (device.tracking.gpstime > 0)
            ? window.moment(device.tracking.gpstime).utc().format('YY-MM-DD HH:mm:ss')
            : '0'
          device.time.gpsFormat = (device.tracking.gpstime > 0)
            ? window.moment(device.tracking.gpstime).fromNow(true)
            : '-'
          device.time.gpsStyle = (timediff < 120000) ? 'success' : (timediff < 240000) ? 'warning' : 'danger'
        } else {
          device.time.gpsDate = '0'
          device.time.gpsFormat = '-'
          device.time.gpsStyle = 'danger'
        }

        const batt = device.tracking.data.battery || 0

        device.styles = {
          time: (timediff < 60000) ? 'success' : (timediff < 120000) ? 'warning' : 'danger',
          batt: (batt > 3800) ? 'success' : (batt > 3750) ? 'warning' : 'danger'
        }
      })

      this.update()
    }

    this.updateTimes = () => {
      $('tbody').find('tr').each((a, b) => {
        let $g = jQuery(b).find('.g')
        let gpstime = $g.data('order')
        let gpstimenow = window.moment(gpstime).fromNow()
        let gpstimediff = Date.now() - gpstime
        let gStyle = (gpstimediff < 60000) ? 'success' : (gpstimediff < 240000) ? 'warning' : 'danger'

        let $s = jQuery(b).find('.s')
        let servertime = $s.data('order')
        let servertimenow = window.moment(servertime).fromNow()
        let servertimediff = Date.now() - servertime
        let sStyle = (servertimediff < 60000) ? 'success' : (servertimediff < 240000) ? 'warning' : 'danger'

        if (gpstime) $g.html(`<span data-toggle="tooltip" title="${gpstime}" class="text-${sStyle}"> ${gpstimenow}</span>`)
        if (!gpstime) $g.html('-')
        if (servertime) $s.html(`<span data-toggle="tooltip" title="${servertime}" class="text-${gStyle}"> ${servertimenow}</span>`)
        if (!servertime) $s.html('-')
      })
    }

    this.openSetGroup = (e) => {
      e.preventDefault()
      $('#groups-modal').modal('show')

      const devices = this.selectedDevices = this.getCheckedDevices()
      const selectedTotalDevices = devices.length
      $('#selected-total-devices').empty().append(selectedTotalDevices)

      let devicesTags = ''
      devices.forEach((device) => {
        devicesTags += '<span class="label label-default mr-1" id="label-' + device._id + '">' + device.name + '</span>'
      })

      $('#selected-devices-list').empty().append(devicesTags)
    }

    this.setGroup = () => {
      const group = $('#text-group-name').val()
      async.each(this.selectedDevices, (device, cb) => {
        $('#label-' + device._id).addClass('label-danger').removeClass('label-default')
        utils.fetch({
          endpoint: `/v1/devices/${device._id}`,
          type: 'put',
          update: false,
          data: {'status.group': group},
          callback: (device) => {
            $('#label-' + device._id).addClass('label-success').removeClass('label-danger')

            // actualizamos datatables para habilitar el filtro en la columna group
            let row = window.jQuery('tr[data-id="'+device._id+'"]').get()
            const rowData = this.table.row(row).data() || null

            if (rowData) {
              rowData[colIndex.group] = group
              this.table.row(row).data(rowData)
            }

            cb(null)
          }
        })()
      }, (err) => {
        if (err) console.log(err)
        $('#groups-modal').modal('hide')
        this.table.row().column(colIndex.group).search(group)
        this.table.row().draw()
      })
    }

    this.clearTableFilters = (e) => {
      e.preventDefault()
      utils.clearDatatableFilters(this.table)
      $('.filters input').val('')
    }

    this.getCheckedDevices = () => {
      const devicesIds = []
      const devices = []

      // obtenemos primero las ids
      window.$(this.root).find('tbody').find('.select-item:checked').each((index, input) => {
        const deviceId = input.dataset.id
        devicesIds.push(deviceId)
      })

      // buscamos ahora los objetos device utilizando las ids
      let len = this.devices.length
      while (len--) {
        if (devicesIds.indexOf(this.devices[len]._id) >= 0) {
          devices.push(this.devices[len])
        }
      }

      devices.sort((a, b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })

      return devices
    }

    // hay que crear el botón de opciones de tabla (arriba derecha) y asignarle
    // los eventos "onclick".
    this.setupCogButton = () => {
      $('.cog-button')
        .empty()
        .addClass('pull-left mr-1')
      $('#btn-cog-button').clone(true, true).appendTo('.cog-button')

      $(this.root).on('click', '#open-set-group', this.openSetGroup)
      $(this.root).on('click', '#table-clear-filters', this.clearTableFilters)
    }

    /**
     * En función del tipo de usuario queremos filtrar que columnas se muestran
     * y cuales son los indices de cada columna
     */
    this.getUserTableOptions = () => {
      colIndex = {
        check: 0,imei: 1, name: 2, customName: 3, group: 4,
        gtime: 5, stime: 6,
        vbat: 7, vin: 8, vsys: 9,
        loc: 10, hdop: 11, sats: 12, gsm: 13,
        firmware: 14, sticker: 15, sim: 16, account: 17
      }
      let columns = [
        {visible: true, name: 'check-row'},
        {visible: true, name: 'imei'},
        {visible: true, name: 'name'},
        {visible: false, name: 'customName'},
        {visible: true, name: 'group'},
        {visible: true, name: 'gtime'},
        {visible: true, name: 'stime'},
        {visible: true, name: 'vbat'},
        {visible: true, name: 'vin'},
        {visible: true, name: 'vsys'},
        {visible: true, name: 'loc'},
        {visible: false, name: 'hdop'},
        {visible: false, name: 'sats'},
        {visible: false, name: 'gsm'},
        {visible: false, name: 'firmware'},
        {visible: false, name: 'sticker'},
        {visible: false, name: 'sim'},
        {visible: true, name: 'account'}
      ]

      if (!window.IS_ADMIN) {
        colIndex = {
          name: 0, customName: 1,
          gtime: 2, stime: 3,
          vbat: 4, vin: 5, vsys: 6,
          loc: 7, hdop: 8, sats: 9, gsm: 10,
        }

        columns = [
          {visible: true, name: 'name'},
          {visible: true, name: 'customName'},
          {visible: true, name: 'gtime'},
          {visible: true, name: 'stime'},
          {visible: true, name: 'vbat'},
          {visible: true, name: 'vin'},
          {visible: true, name: 'vsys'},
          {visible: true, name: 'loc'},
          {visible: false, name: 'hdop'},
          {visible: false, name: 'sats'},
          {visible: false, name: 'gsm'}
        ]
      }

      return columns
    }

    const tableOptions = {
      order: [[tableOrder, 'desc']],
      columns: this.getUserTableOptions(),
      bSortCellsTop: true,
      dom: 'B<"cog-button">rtip',
      stateSave: true,
      stateSaveParams: function (settings, data) {
        data.columns.forEach((col, index) => {
          if (col.search.search) {
            $('td[data-index="'+index+'"]').find('input').val(col.search.search)
          }
        })
      },
      buttons: [{
        extend: 'print',
        className: 'btn-sm'
      },{
        extend: 'colvis',
        columnText: function ( dt, idx, title ) {
          return (idx+1)+': '+title;
        },
        className: 'btn-sm ml-2',
        text: 'Columns'
      }]
    }

    this.on('mount', this.loadDevices)
    // this.on('mount', () => window.emitter.on(`position`, this.socketIoData))
    this.on('unmount', () => {
      // window.emitter.off(`position`, this.socketIoData)
      if (this.table) {
        this.table.destroy()
      }
    })
    // setInterval(this.updateTimes, 1000)
  </script>

  <style type="text/css">
    .dropdown-menu {
      min-width: 200px !important;
    }
    .dropdown-menu strong {
      margin: 0 10px;
    }

    .abc-checkbox label::after, .abc-checkbox label::before {
      left: 5px;
    }

    .abc-checkbox input[type=checkbox], .abc-checkbox input[type=radio] {
      position: absolute;
    }

    td {
      vertical-align: middle !important;
    }
  </style>
</devices-user>
