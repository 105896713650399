<accounts-edit-user>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/accounts" class="btn btn-default btn-sm" data-toggle="tooltip" title="Go back">
        <span class="fa fa-arrow-left"></span> return</a>
    </div>
    <h1 if={!account}>Edit Account  {opts._id}</h1>
    <h1 if={account}>Edit Account "{account.username}" <small>{account.email}</small></h1>
  </div>

  <div class="container-fluid" if={account}>
    <div class="row">
      <form data-toggle="validator" class="form" onsubmit={save}>
        <div class="col-md-8">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Account info</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="username">username</label>
                    <input disabled={!window.IS_ADMIN} class="form-control" id="username" onkeyup={updateValue} onchange={updateValue} value="{account.username}" type="text" required>
                  </div>
                  <div class="form-group">
                    <label for="email">email</label>
                    <input class="form-control" id="email" onkeyup={updateValue} onchange={updateValue} value="{account.email}" type="email" required>
                  </div>
                  <div if={window.IS_ADMIN} class="form-group">
                    <label for="freeze">Freeze</label>
                    <select id="freeze" class="form-control" onchange={updateValue} data-format="boolean" required>
                      <option selected={account.freeze} value="1">Yes</option>
                      <option selected={!account.freeze} value="0">No</option>
                    </select>
                  </div>
                  <div if={window.IS_ADMIN} class="form-group">
                    <label for="role">Role</label>
                    <select id="role" class="form-control" onchange={updateValue} required>
                      <option selected={account.role === "user"} value="user">User</option>
                      <option selected={account.role === "admin"} value="admin">Admin</option>
                      <option selected={account.role === "member"} value="member">Member</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Full name</label>
                    <input class="form-control" id="details.name" onkeyup={updateValue} onchange={updateValue} value="{account.details.name}" type="text">
                  </div>
                  <div class="form-group">
                    <label for="company">Company</label>
                    <input class="form-control" id="details.company" onkeyup={updateValue} onchange={updateValue} value="{account.details.company}" type="text">
                  </div>
                  <div class="form-group">
                    <label for="phone">phone</label>
                    <input class="form-control" id="details.phone" onkeyup={updateValue} onchange={updateValue} value="{account.details.phone}" type="text">
                    <span class="help-block with-errors"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <button class="btn btn-primary" type="submit">Save account info</button>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Change password</h3>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <label for="password">Current password</label>
                <input class="form-control" id="password" value="" type="password">
              </div>
              <div class="form-group">
                <label for="new-password">New password</label>
                <input class="form-control" id="new-password" type="password">
              </div>
            </div>
            <div class="panel-footer">
              <button class="btn btn-primary" type="submit">Change password</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="container-fluid" if={!account}>
    <p>Loading...</p>
  </div>

  <script>
    import {fetch, updateValue} from '../utils/utils'

    this.account = null

    this.loadAccount = () => {
      fetch({
        endpoint: `/v1/accounts/${this.opts._id}?select=**`,
        update: false,
        callback: (account) => {
          this.account = account
          this.account.details = this.account.details || {}
          this.update()
        }
      })()
    }

    this.updateValue = (e) => {
      e.preventUpdate = true
      const $el = window.$(e.currentTarget) || window.$(e.target)
      const property = $el.attr('id').split('.')
      let value = $el.val()

      const format = $el.data('format')
      if (format) {
        switch (format) {
          case 'boolean':
            value = Boolean(parseInt(value), 10)
            break
        }
      }

      switch (property.length) {
        case 1:
          this.account[property[0]] = value
          break
        case 2:
          this.account[property[0]] = this.account[property[0]] || {}
          this.account[property[0]][property[1]] = value
          break
        case 3:
          this.account[property[0]] = this.account[property[0]] || {}
          this.account[property[0]][property[1]] = this.account[property[0]][property[1]] || {}
          this.account[property[0]][property[1]][property[2]] = value
          break
      }

      console.log('this.account', this.account)
    }

    this.save = (e) => {
      e.preventUpdate = true
      e.preventDefault()

      $('form').validator('validate')
      if ($('.form-group.has-error').length) {
        return window.swal('Error while saving', 'The form has errors, please check it.', 'error')
      }

      let password = $('#new-password').val().trim()
      if (password) {
        this.account.password = password;
      }

      fetch({
        type: 'put',
        datType: 'json',
        endpoint: `/v1/accounts/${this.opts._id}`,
        data: this.account,
        update: false,
        callback: (data) => {
          window.swal('Account saved!', '', 'success')
        },

        error: () => {
          window.swal('Save failed!', 'Something went wrong saving the account.', 'error')
        }
      })()
    }

    this.on('mount', this.loadAccount)
  </script>

</accounts-edit-user>
