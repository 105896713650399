import riot from 'riot'
import route from 'riot-route'
import {setupAjax, activateMenuOption} from './modules/utils/utils.js'
import acl from './acl'

// --
let loginCookie = window.Cookies.get('m2mss-login') || ''
let userCookie = window.Cookies.get('m2mss-user')
window.__USER = userCookie = (userCookie) ? JSON.parse(userCookie) : {}
window.IS_ADMIN = window.__USER.role === 'admin'
window.jQuery('#own-account').html(userCookie.username)

if (loginCookie) {
  window.$('body').hide()
  window.$('body').removeClass('hide')
  window.$('body').fadeIn()
}

// --
setupAjax()

// --
window.modules = {}
if (userCookie.role === 'admin') {
  window.modules = acl.admin
} else {
  window.modules = acl.user
}

// --
window.emitter = riot.observable()
require('./modules/iot/iot')()

// --
let first = false
route(function (module, id = 0, action = '') {
  module = module || 'devices'
  // nos sirve para establecer el menú activo, ya y que si hay action
  // la variable module se mezcla con este
  const originalModule = module

  if (!loginCookie) {
    // a priori se puede entra aquí si se pierden los credenciales mientras
    // se navega o se accede al administrador sin iniciar sesión
    document.location.href = '/login.html'
    return
  }

  // el nombre del modulo cambia cuando tenemos "action". Añadimos la accíón
  // y se queda un nombre tipo "sims-add", para el cual hay un archivo .tag
  if (action) module += action

  // modulos con "." indican páginas estáticas HTML, las evitamos...
  if (module.indexOf('.') > -1) return

  if (window.modules[module]) {
    window.$('#app').hide()
    riot.mount('#app', window.modules[module], {_id: id})
    window.$('#app').fadeIn('fast', () => {
      activateMenuOption(originalModule)

      if (!first) {
        first = true
        return
      }

      window.$('html,body').animate({
        scrollTop: window.$('body, html').offset().top
      }, 500)
    })
    return
  }

  riot.mount('#app', window.modules.err404)
})

// --
route.base('/')
route.start(true)

// --
let navbar
if (userCookie.role === 'admin') {
  navbar = require('./modules/navbar/admin.tag')
} else {
  navbar = require('./modules/navbar/user.tag')
}
riot.mount(navbar)
