<sims-add>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/sims" class="btn btn-default" data-toggle="tooltip" title="Go back to sims list">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1>Add Sim</h1>
  </div>

  <div class="container-fluid">
    <div class="row col-md-6 col-md-offset-3">
      <form if="{opts._id == 0}" class="form form-horizontal">
        <div class="form-group">
          <label class="control-label col-md-2 col-sm-12">ICCID</label>
          <div class="col-md-5 col-sm-12">
            <input maxlength="22" type="text" class="form-control" id="iccid" value="" placeholder="iccid">
          </div>
          <div class="col-md-5 col-sm-12">
            <button onclick={save} type="button" class="btn btn-primary btn-save">Save &amp; continue</button>
          </div>
        </div>
      </form>

      <div if="{opts._id != 0}" class="alert alert-success">
        Ok makey, created! redirecting to the sim or click
          <a href="{opts._id}">here</a>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils');
    const route = require('riot-route');
    const that  = this;

    this.showCreated = () => {

    }

    this.save = () => {
      const iccid = utils.parseICCID(jQuery('#iccid').val());

      if (!iccid) {
        return utils.messageAlert.show('Invalid ICCID number.');
      }

      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/sims/' + iccid,
        type: 'post',
        update: false,
        callback: function(data) {
          that.opts._id = data._id;
          that.id = data._id;
          that.update();
          setTimeout(function() {
            route('/sims/' + data._id + '/edit');
          }, 3000);
        }
      }

      utils.fetch(fetchOptions)();
    }
  </script>

</sims-add>
