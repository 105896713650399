<maps-manage-categories>
  <div class="modal fade" id="modal-categories" tabindex="-1" role="dialog" aria-labelledby="modal-categories-label">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal-categories-label">Category: {category.name}</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="input-group">
              <input id="category-name" class="form-control input-sm" value="{category.name}" onkeyup={updateCategory}>
              <span class="input-group-btn">
                <button class="btn btn-default btn-sm" onclick={updateCategory} type="button">Update Category Name</button>
              </span>
            </div
          </div>

          <hr />

          <div class="form-group">
            <input id="search-category-device" class="form-control" placeholder="add device">
          </div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Device already on this category</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="parent-showing" each={device in devices}>
                <td>{device.name}</td>
                <td class="adjust-cell">
                  <button type="button" class="btn btn-danger btn-xs children-hidden" data-id={device._id} onClick={removeDevice}>
                    <span class="fa fa-remove"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <p><small class="text-mutted">Can not find your device? A device must
            be added to the event before it can be added to a category.</small></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <script>
    var utils = require('../utils/utils')
    var that = this
    this.test = null
    this.category = {}
    this.devices = []

    this.on('mount', () => {
      $('#search-category-device').on('select2:select', function(e) {
        var deviceId = e.params.data.id
        that.addDevice(deviceId)
      })
    })

    this.on('update', this.open)

    this.open = (categoryId) => {
      categoryId = categoryId || this.category._id
      window.$('#modal-categories').modal('show')
      this.categoryId = categoryId
      this.getCategory(categoryId)
      this.getCategoryDevices()
      this.initSearchDevices()
    }

    this.updateCategory = (e) => {
      if (e && e.type === 'keyup') {
        // solo si la tecla es intro
        if (e.keyCode !== 13) return
      }

      const $button = window.$(e.currentTarget)
      const $input = window.$('#category-name')
      const name = $input.val()
      this.category.name = name
      this.parent.update()

      utils.fetch({
        url: window.__CONF.apiUrl + '/v1/categories/' + this.category._id,
        update: false,
        type: 'put',
        button: $button,
        data: this.category,
        callback: () => {
          $input.focus()
          window.swal('Category updated', '', 'success')
        }
      })()
    }

    this.addDevice = (deviceId) => {
      var fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/categories/' + this.category._id + '/device',
        type: 'post',
        update: false,
        data: {_device: deviceId},
        callback: (data) => {
          $('#search-category-device').val('').trigger('change')
          this.opts.map._categories = data._categories
          this.open()
          this.parent.update()
          this.parent.trigger('category-update')
        }
      }

      utils.fetch(fetchOptions)()
    }

    this.removeDevice = (e) => {
      var deviceId = $(e.currentTarget).data('id')
      var fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/categories/' + this.category._id + '/device',
        type: 'delete',
        update: false,
        data: {
          _device: deviceId
        },
        callback: function(data) {
          $('#search-category-device').val('').trigger('change')
          that.opts.map._categories = data._categories
          that.open()
          that.parent.update()
          that.parent.trigger('category-update')
        }
      }

      utils.fetch(fetchOptions)()
    }

    // obtenemos
    this.getCategory = (categoryId) => {
      var categories = opts.map._categories
      var i = categories.length
      while (i--) {
        if (categories[i]._id === categoryId) {
          this.category = categories[i]
        }
      }
    }

    this.getCategoryDevices = () => {
      this.devices = []
      var devices = this.category._devices
      var i = devices.length
      while (i--) {
        var device = this.getDevice(devices[i])
        this.devices.push(device)
      }
    }

    this.getDevice = (deviceId) => {
      var devices = opts.map._devices
      var i = devices.length
      while (i--) {
        if (devices[i]._id === deviceId) {
          return devices[i]
        }
      }
      return {}
    }

    // devuelve un array de devices para el select2
    this.getMapDevices = () => {
      var select2Devices = []
      var devices = opts.map._devices
      var i = devices.length
      while (i--) {
        if (this.category._devices.indexOf(devices[i]._id) === -1) {
          select2Devices.push({
            id: devices[i]._id,
            text: devices[i].name
          })
        }
      }

      return select2Devices
    }

    this.initSearchDevices = () => {
      // fix select2 bug en bootstrap modal
      window.$.fn.modal.Constructor.prototype.enforceFocus = function() {};
      $('#search-category-device').select2({
        theme: 'bootstrap',
        data: this.getMapDevices(),
        placeholder: 'Add Device',
        allowClear: true,
        initSelection: function(element, callback) {}
      })
    }

  </script>
</maps-manage-categories>
