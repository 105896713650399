<awesome-checkbox>
  <div class="abc-checkbox abc-checkbox-success">
    <input type="checkbox" class="select-item" data-id={opts.data._id} id="check-{opts.data._id}">
    <label for="check-{opts.data._id}">{opts.labeltext}</label>
  </div>

  <script>
    this.opts.data = this.opts.data || {}
  </script>
</awesome-checkbox>
