<device-last-tracking>

  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Last Tracking Data
        <a nolink href="#" class="pull-right"
          data-toggle="popover"
          data-title="About last tracking data"
          data-content="Sometimes you can see two different dates, one for GPS and one for battery. This is because the tracker continues to send battery data to the server when no GPS signal is found, so only the battery date is updated and GPS keeps the previous date."
          data-placement="right"
        ><i class="fa fa-info-circle"></i></a></h3>
    </div>
    <div class="panel-body">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>Battery
            <a nolink href="#" class="pull-right"
              data-toggle="popover"
              data-title="Internal battery info"
              data-content="Battery range is between 3600 (0% charge) and 4100 (100% charge). There may be differences between different batteries due to the nature of lithium batteries."
              data-placement="right"
            ><i class="fa fa-info-circle"></i></a>
            </th>
            <td>{device.tracking.data.battery}mv
              <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.servertime).format('DD-MM-YYYY HH:mm:ss')}">
                {window.moment(device.tracking.servertime).fromNow() || ''}</small>
            </td>
          </tr>
          <tr>
            <th>Ext. Battery
            <a nolink href="#" class="pull-right"
              data-toggle="popover"
              data-title="External battery info"
              data-content="The range will depend on the external battery used by the end user. A value below 6000 (mV) may indicate that no external battery is connected."
              data-placement="right">
            <i class="fa fa-info-circle"></i></a>
            </th>
            <td>
              {device.tracking.data.extbattery || 0}mv <small>(vsys: {device.tracking.data.vsys}mv)</small>
              <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.servertime).format('DD-MM-YYYY HH:mm:ss')}">
                {window.moment(device.tracking.servertime).fromNow() || ''}</small>
            </td>
          </tr>
          <tr>
            <th>Location</th>
            <td if={device.tracking.data.loc}><a target="_blank" data-toggle="tooltip" title="View location on Open Street Map (external link)"   href="https://www.openstreetmap.org/?mlat={device.tracking.data.loc[1]}&mlon={device.tracking.data.loc[0]}&zoom=16">
              {device.tracking.data.loc[1]}, {device.tracking.data.loc[0]}</a>
              <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.gpstime).format('DD-MM-YYYY HH:mm:ss')}">
                {window.moment(device.tracking.gpstime).fromNow()}</small>
            </td>
            <td if={!device.tracking.data.loc}>-</td>
          </tr>
          <tr>
            <th>GPS (<a href="https://en.wikipedia.org/wiki/Dilution_of_precision_(navigation)#Meaning_of_DOP_Values">HDOP</a>)</th>
            <td if={device.tracking.data.gps}>{device.tracking.data.gps}
            </td>
            <td if={!device.tracking.data.gps}>-</td>
          </tr>
          <tr if={device.tracking.data.sats}>
            <th>Sats
              <a nolink href="#" class="pull-right"
              data-toggle="popover"
              data-title="Number of satellites"
              data-content="The number of GPS satellites. At least 4 satellites are required for a GPS fix but the signal could be poor. More than 8 satellites can offer quite accurate precission. As of 2018 the <a href='https://tycho.usno.navy.mil/gpscurr.html'>GPS constellation</a> consists of 32 satellites, not all in the same hemisphere as you."
              data-placement="right"
            ><i class="fa fa-info-circle"></i></a>
            </th>
            <td>{device.tracking.data.sats}</td>
          </tr>
          <tr if={device.tracking.data.gsm}>
            <th>GSM
            <a nolink href="#" class="pull-right"
              data-toggle="popover"
              data-title="GSM Signal Quality"
              data-content="A numnber between 1 (poor) and 30 (excellent) to indicate the quality of the GSM signal"
              data-placement="right"
            ><i class="fa fa-info-circle"></i></a>
            </th>
            <td>{device.tracking.data.gsm}</td>
          </tr>
          <tr>
            <th>Altitude (meters)</th>
            <td if={device.tracking.data.alt}>{parseInt(device.tracking.data.alt)}</td>
            <td if={!device.tracking.data.alt}>-</td>
          </tr>
          <tr if={device.tracking.data.sog}>
            <th>Speed (km/h)</th>
            <td>{device.tracking.data.sog}</td>
          </tr>
          <tr if={device.tracking.data.cog}>
            <th>Direction</th>
            <td>{device.tracking.data.cog}º
              <span style="transform: rotate({device.tracking.data.cog}deg); display: inline-block; float: right">&#8648;</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  <script>
    this.device = this.parent.device
  </script>
</device-last-tracking>
