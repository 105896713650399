<sims-details>
  <!-- Modal -->
  <div class="modal fade" id="sim-details" tabindex="-1" role="dialog" aria-labelledby="device-details-label">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="device-details-label">Details for SIM ICCID: {opts.iccid}</h4>
        </div>
        <div class="modal-body">
          <div id="loading" class="hide">loading...</div>

          <div if={opts.details}>
            <textarea id="sim-details-text" class="form-control" disabled="disabled" rows="30">
              {JSON.stringify(opts.details, null, 4)}
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils.js')
    // podríamos gastar la propiedad window.__CONF.apiUrl
    // pero aquí necesitamos siempre, si o si, que la Api sea la de producción
    // por lo que esta propiedad no nos vale cuando contiene la url de desarrollo.
    const apiUrl = 'https://api.m2msystemsource.com'

    this.loadDetails = () => {
      $('#loading').removeClass('hide')

      const options = {
        url: apiUrl + `/v1/sims/${this.opts.iccid}/details`,
        update: false,
        callback: (simData) => {
          this.opts.details = simData
          this.update()
        },
        fail: () => {
          swal({title: 'something went wrong. Try again please.', type: 'error'})
        },
        always: () => {
          $('#loading').addClass('hide')
        }
      }
      utils.fetch(options)()
    }

    this.parent.on('sim-details', (sim) => {
      console.log('okok2')
      this.opts = sim
      this.update()
      this.loadDetails()
      $('#sim-details').modal('show')
    })

  </script>
</sims-details>
