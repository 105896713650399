<sims-admin>

  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/sims/0/add" class="btn btn-default">
        <span class="fa fa-plus-sign"></span> Add Sim</a>
    </div>
    <h1>Sims</h1>
  </div>

  <div class="container-fluid">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th data-orderable="false">
            <awesome-checkbox data={opts}></awesome-checkbox>
          <th data-sort="desc" style="width: 50px">id</th>
          <th>iccid</th>
          <th>imsi</th>
          <th>status</th>
          <th>provider</th>
          <th>owner</th>
          <th data-orderable="false"></th>
        </tr>
      </thead>
      <thead class="filter"s>
        <tr>
          <td></th>
          <td class="has-search input-search-sm"></td>
          <td class="has-search"></td>
          <td class="has-search"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search input-search-sm"></td>
          <td></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={opts.items} class="parent-showing" data-iccid="{iccid}">
          <td class="text-center">
            <awesome-checkbox data={this}></awesome-checkbox>
          <td>{_id}</td>
          <td><a href="/sims/{iccid}/edit"><strong>{iccid}<strong></a></td>
          <td>{imsi}</td>
          <td>{(status) ? status : 'unknow'}</td>
          <td><a if={_provider} href="/providers/{_provider._id}/edit">{_provider.name}</a></td>
          <td><a if={_account} href="/accounts/{_account._id}/edit">{_account.username}</a></td>
          <td class="text-center">
            <div class="btn-group children-hidden">
              <a href="/sims/{iccid}/remove" class="btn btn-danger btn-xs" onclick={removeItem}>
                <span class="fa fa-remove"></span></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <script>
    const that = this
    const utils = require('../utils/utils');
    const select = 'iccid,imsi,status,_provider,_account';
    const populate = '_provider:name;_account:username';
    const auth = window.Cookies.get('m2mss-login')

    const fetchOptions = {
      opts: opts,
      url: window.__CONF.apiUrl + '/v1/sims-datatables?limit=0&select='+select+'&populate=' + populate,
    }

    const tableOptions = {
      order: [[3, 'asc']],
      "processing": true,
      "search": true,
      "serverSide": true,
      "ajax": {
        url: window.__CONF.apiUrl + '/v1/sims-datatables?dd=1&populate=' + populate,
        type: "GET",
        headers: {Authorization: 'Basic ' + auth}
      },
      "columns": [
        {
          // checkbox al centro
          createdCell: function ($td) {
            window.$($td).addClass('text-center')
          }
        },
        {
          // añadimos el attributo `data-id` al row (<tr>)
          "width": "10px",
          createdCell: function ($td, val) {
            window.$($td).parent('tr').attr('data-id', val)
          }
        },
        {
          // convertimos el ICCID en un link clickeable para editar la sim
          createdCell: function ($td, val) {
            window.$($td).html('<a href="/sims/'+val+'/edit"><strong>'+val+'<strong></a>')
          }
        },
        {"width": "50px"},
        {defaultContent: 'unknow', data: null},
        null,
        null,
        {
          createdCell: function ($td) {
            window.$($td).addClass('text-center')
            window.$($td).parent('tr').addClass('parent-showing')
          }
        }
      ],

      createdRow: function ( row, data, index ) {
        if ( data[5].replace(/[\$,]/g, '') * 1 > 150000 ) {
          $('td', row).eq(5).addClass('highlight')
        }
      }
    }

    this.removeItem = (e) => {
      e.preventUpdate = true;
      if (e.altKey && e.ctrlKey && e.shiftKey) {
        e.preventDefault()
        e.stopPropagation()

        const $el = $(e.currentTarget);
        const iccid = $el.closest('tr').data('iccid');

        utils.removeSim(iccid, function() {
          $('tr[data-iccid="'+iccid+'"]').fadeOut('slow', function() {
            $(this).remove()
          })
        })
      }
    }

    //this.on('mount', utils.fetch(fetchOptions));
    this.on('mount', utils.setupDatatables(tableOptions))
  </script>
</sims-admin>
