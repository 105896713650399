<accounts-add-user>
  <div class="container-fluid container-fluid-header">
    <h1>Add Member</h1>
  </div>

  <div id="container-fluid">
    <div class="row">
      <div class="col-md-4 col-md-offset-2">
        <form class="form-horizontal" data-toggle="validator" onsubmit={save}>
          <div class="form-group">
            <label for="username" class="control-label col-md-2">Username</label>
            <div class="col-md-10">
              <input type="text" class="form-control" id="username" required value="test" pattern="^[_A-z0-9]*$" minlength="3" maxlength="15">
              <span class="help-block">Only alphanumeric characters. Min. 3 chars, max 15.</span>
            </div>
          </div>
          <div class="form-group">
            <label for="email" class="control-label col-md-2">Email</label>
            <div class="col-md-10">
              <input type="email" class="form-control" id="email" onkeypress={noCtrl} required value="test@test.com">
            </div>
          </div>
          <div if={window.IS_ADMIN} class="form-group">
            <label for="role" class="control-label col-md-2">Role</label>
            <div class="col-md-10">
              <select id="role" class="form-control">
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="member">Member</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="password" class="control-label col-md-2">Password</label>
            <div class="col-md-10">
              <input type="password" class="form-control" id="password" required value="test@test.com">
            </div>
          </div>
          <div class="form-group">
            <label for="re-password" class="control-label col-md-2">Confirm Password</label>
            <div class="col-md-10">
              <input type="password" class="form-control" id="re-password" required value="test@test.com" data-match="#password">
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-10 col-md-offset-2">
              <button class="btn btn-primary btn-block" type="submit">Save</button>
            </div>
          </div>
        </form>
      </div> <!-- .col-md-6 -->
      <div class="col-md-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title"><span class="fa fa-info"></span> About member accounts</h3>
          </div>
          <div class="panel-body">
            <p>Members you add to your account can access maps and view device status. These users have read-only permissions, so they won't be able to make changes to maps or devices.</p>
            <p>You can add members to a map by editing the map in the members section.
              <a href="/maps">Visit maps</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'

    this.noCtrl = (e = {}) => {
      e.preventUpdate = true
      if (e.keyCode === 17 || e.ctrlKey === true) {
        e.preventDefault()
        return
      }
    }

    this.save = (e = {}) => {
      if (e) {
        e.preventUpdate = true
        e.preventDefault()
      }

      const data = {
        username: $('#username').val(),
        email: $('#email').val(),
        password: $('#password').val(),
        role: $('#role').val() || 'member'
      }

      fetch({
        update: false,
        data: data,
        url: window.__CONF.apiUrl + '/v1/accounts',
        type: 'post',
        callback: (data) => {
          window.swal('Success!', 'The account has been created as a member', 'success')
        }
      })()
    }
  </script>
</accounts-add-user>
