<logout>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h1>You are now logged out.</h1>
        <p>Have a nice day :)</p>
        <p><a href="/login.html" onclick={handle}>Login form</a></p>
      </div>
    </div>
  </div>


  <script>
    this.handle = () => {
      document.location.href='/login.html';
    }
    Cookies.remove('m2mss-login', {path: '/', domain: '.' + window.__CONF.domain});

    $('.navbar').hide();
    $('.sidebar').hide();
  </script>

</logout>
