<error502>

  <div class="container">
    <h1>Error 502</h1>
    <p class="lead">Sorry, the server is bussy or not working well right now.
      Please try again latter, if the problem persists drop a line a to the
      admin</p>
  </div>

</error502>
