<maps-add>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/maps" class="btn btn-default" data-toggle="tooltip" title="Go back to maps list">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1>Create Map</h1>
  </div>

  <div class="container-fluid">
    <div class="row col-md-8 col-md-offset-2">
      <div class="alert alert-info text-center alert-fill-in">
        Fill in the title and press the button to continue editing
      </div>
      <form if="{opts._id == 0}" class="form form-horizontal">
        <div class="form-group">
          <label class="control-label col-md-2 col-sm-12">Title</label>
          <div class="col-md-5 col-sm-12 input-icon">
            <i id="icon-ok"
              data-toggle="tooltip"
              class="icon-input fa fa-ok text-success"
              title="Title is valid, go little boy, go!"></i>
            <i id="icon-error"
              data-toggle="tooltip"
              class="icon-input fa fa-remove text-danger"
              title="Title is not valid, duplicated slug :("></i>
            <i id="check-slug" class="icon-input"><img src="/images/loading.gif" /></i>
            <input type="text" class="form-control" id="title" onKeyup={checkSlug} placeholder="title">
            <span class="help-block">
              <span>slug:</span>
              <span id="title-slug"></span>
            </span>
          </div>
          <div class="col-md-5 col-sm-12">
            <button id="btn-save" onclick={save} type="button" class="btn btn-primary btn-save">Save &amp; continue</button>
          </div>
        </div>
      </form>

      <div if="{opts._id != 0}" class="alert alert-success">
        Ok makey, created! redirecting to the map, or click
          <a href="/maps/{opts._id}/edit">here</a>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils')
    const route = require('riot-route')
    const that = this

    let loadingTimer = null
    let $loading = null
    let $title = null
    let $slug = null
    let $errorIcon = null
    let $iconOk = null
    let $save = null
    let $iconError = null

    this.init = () => {
      $loading = window.$('#check-slug')
      $title = window.$('#title')
      $slug = window.$('#title-slug')
      $iconOk = window.$('#icon-ok')
      $save = window.$('#btn-save')
      $iconError = window.$('#icon-error')
      $loading.hide()
    }

    this.save = () => {
      const title = jQuery('#title').val()
      const slug = utils.slugify(title)

      if (!title) {
        return utils.messageAlert.show('Fill in the title, mother fucker!.')
      }

      if (this.hasError()) {
        return utils.messageAlert.show('Sorry, correct the title, the slug is duplicated.')
      }

      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot',
        type: 'post',
        update: false,
        data: {
          slug: slug,
          title: title,
          type: 'tracking'
        },
        callback: function(data) {
          that.opts._id = data._id
          that.id = data._id
          that.update()
          $('.alert-fill-in').remove()
          setTimeout(function() {
            route('/maps/' + data._id + '/edit')
          }, 3000)
        }
      }

      window.swal({
        title: title,
        text: 'The map will be created now, you can continue editing it below, ok?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Ok, go!',
        cancelButtonText: 'No, stop, go back',
        closeOnConfirm: true
      },
      function () {
        utils.fetch(fetchOptions)()
      })
    }

    this.clearError = () => {
      $iconOk.hide()
      $iconError.hide()
      $title.closest('.form-group').removeClass('has-error')
    }

    this.setError = () => {
      $iconError.show()
      $title.closest('.form-group').addClass('has-error')
    }

    this.setOk = () => {
      $iconOk.show()
      $save.attr('disabled', false)
    }

    this.hasError = () => {
      return $title.closest('.form-group').hasClass('has-error')
    }

    // comprobamos el slug con cada pulsación en el campo título
    // utilizamos un timeOut para no acumular checks o realizar multiples
    // intentos en un breve espacio de tiempo
    this.checkSlug = () => {
      clearTimeout(loadingTimer)
      this.clearError()
      loadingTimer = null
      $loading.show()
      $save.attr('disabled', true)

      var slug = utils.slugify($('#title').val())
      $slug.html(slug)
      var data = {slug: slug}

      loadingTimer = setTimeout(function() {
        var url = window.__CONF.apiUrl + '/v1/iot/check-slug'

        var fetchOptions = {
          url: url,
          type: 'get',
          update: false,
          data: data,
          callback: function(data) {
            if (data.exists) {
              that.setError()
            } else {
              that.setOk()
            }
          },
          always: function () {
            $loading.hide()
          }
        }

        utils.fetch(fetchOptions)()
      }, 300)
    }

    this.on('mount',this.init);
  </script>

</maps-add>
