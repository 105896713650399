<devices-admin-add>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/devices" class="btn btn-default" data-toggle="tooltip" title="Go back to devices list">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1>Create Device</h1>
  </div>

  <div class="container-fluid">
    <div class="row col-md-8 col-md-offset-2">
      <div class="alert alert-info text-center" id="alert-fill-in">
        Fill in the device name and press the button to continue editing
      </div>
      <form if="{opts._id == 0}" class="form form-horizontal">
        <div class="form-group">
          <label class="control-label col-md-2 col-sm-12">Device Name</label>
          <div class="col-md-5 col-sm-12 input-icon">
            <i id="icon-ok"
              data-toggle="tooltip"
              class="icon-input fa fa-check text-success"
              title="Name is valid, go little boy, go!"></i>
            <i id="icon-error"
              data-toggle="tooltip"
              class="icon-input fa fa-ban text-danger"
              title="Name is not valid, duplicated name :("></i>
            <i id="check-name" class="icon-input"><img src="/images/loading.gif" /></i>
            <input type="text" class="form-control" id="name" onKeyup={checkName} placeholder="name">
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-md-2 col-sm-12" for="imei">IMEI</label>
          <div class="col-md-5 col-sm-12 input-icon">
            <i id="check-imei" class="icon-input"><img src="/images/loading.gif" /></i>
            <input type="text" class="form-control" id="imei" onKeyup={checkImei} placeholder="name">
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-md-2 col-sm-12" for="imei">Account</label>
          <div class="col-md-5 col-sm-12 input-icon">
            <select id="account" class="form-control">
              <option each={account in accounts} value={account._id}>{account.username}</option>
            </select>
          </div>
        </div>

        <div class="col-md-5 col-sm-12 col-md-offset-2">
          <button id="btn-save" onclick={save} type="button" class="btn btn-primary btn-save" disabled="disabled">Save &amp; continue</button>
        </div>
      </form>

      <div if="{opts._id != 0}" class="alert alert-success">
        Ok makey, created! redirecting to the device, or click
          <a href="/devices/{opts._id}/edit">here</a>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils')
    const route = require('riot-route')
    const that = this
    this.accounts = []

    const $errorIcon = null
    const $okIcon = null
    let $save = null
    let $name = null
    let $imei = null
    let $iconOk = null
    let $iconError = null
    let $loadingName = null
    let $loadingImei = null
    let loadingTimer = null

    this.init = (e) => {
      $loadingName = window.$('#check-name')
      $loadingImei = window.$('#check-imei')
      $name = window.$('#name')
      $imei = window.$('#imei')
      $iconError = window.$('#icon-error')
      $iconOk = window.$('#icon-ok')
      $save = window.$('#btn-save')

      $loadingName.hide()
      $loadingImei.hide()

      this.loadAccounts();
    }

    this.loadAccounts = () => {
      utils.fetch({
        url: window.__CONF.apiUrl + '/v1/accounts/?select=username',
        update: false,
        callback: (accounts) => {
          this.accounts = accounts
          console.log('accounts', accounts)
          this.update()
        }
      })()
    }

    this.save = () => {
      const name = jQuery('#name').val().trim()
      const _id = jQuery('#imei').val().trim()
      const _account = jQuery('#account').val().trim()

      if (!name || !_id) {
        return utils.messageAlert.show('Fill in the name and imei!')
      }

      if (this.hasError()) {
        return utils.messageAlert.show('Sorry, there are errors, the _id or name are duplicated.')
      }

      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/devices',
        type: 'post',
        update: false,
        data: {name, _id, _account},
        callback: function(data) {
          that.opts._id = data._id
          that.id = data._id
          that.update()
          window.swal.close()
          $('#alert-fill-in').hide()
          setTimeout(function() {
            route('/devices/' + data._id + '/edit')
          }, 1000)
        }
      }

      window.swal({
        title: name,
        text: 'The device will be created now, you can continue editing it below, ok?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Ok, go!',
        cancelButtonText: 'No, stop, go back',
        closeOnConfirm: false
      }, () => {
        utils.fetch(fetchOptions)()
      })
    }

    this.enableSaveButton = () => {
      $save.attr('disabled', true)

      window.$('.form-group.has-success').each(function () {
        if ($(this).hasClass('has-success') && $(this).find('input').val().trim() === '') {
          $(this).removeClass('has-success')
        }
      })

      if (window.$('.form-group.has-success').length === 2) {
        $save.attr('disabled', false)
      }
    }

    this.hasError = () => {
      if ($name.closest('.form-group').hasClass('has-error')
        || $imei.closest('.form-group').hasClass('has-error')) {
        return true
      }

      return false
    }

    // comprobamos el name con cada pulsación en el campo título
    // utilizamos un timeOut para no acumular checks o realizar multiples
    // intentos en un breve espacio de tiempo
    this.checkName = () => {
      clearTimeout(loadingTimer)
      $name.closest('.form-group').removeClass('has-error')
      loadingTimer = null
      $loadingName.show()

      $name.closest('.form-group').removeClass('has-success')
      $name.closest('.form-group').removeClass('has-error')

      const name = $('#name').val().trim()
      if (!name) return

      loadingTimer = setTimeout(() => {
        utils.fetch({
          url: window.__CONF.apiUrl + '/v1/devices/check-name/' + name,
          type: 'get',
          update: false,
          callback: (data) => {
            if (data.exists) {
              $name.closest('.form-group').addClass('has-error')
              $name.closest('.form-group').removeClass('has-success')
            } else {
              $name.closest('.form-group').removeClass('has-error')
              $name.closest('.form-group').addClass('has-success')
            }

            this.enableSaveButton()
          },
          always: () => {
            $loadingName.hide()
          }
        })()
      }, 200)
    }

    this.checkImei = () => {
      $imei.closest('.form-group').removeClass('has-error')
      $loadingImei.show()

      const imei = $('#imei').val().trim()
      if (!imei) return

      utils.fetch({
        url: window.__CONF.apiUrl + '/v1/devices/check-imei/' + imei,
        type: 'get',
        update: false,
        callback: (data) => {
          if (data.exists) {
            $imei.closest('.form-group').addClass('has-error')
            $imei.closest('.form-group').removeClass('has-success')
          } else {
            $imei.closest('.form-group').removeClass('has-error')
            $imei.closest('.form-group').addClass('has-success')
          }

          this.enableSaveButton()
        },
        always: () => {
          $loadingImei.hide()
        }
      })()
    }

    this.on('mount',this.init)
  </script>

</devices-admin-add>
