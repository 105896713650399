<devices-user-edit>
  <div if={!device}>
    <p>Loading device...</p>
  </div>
  <div class="container-fluid container-fluid-header" if={device}>
    <div class="btn-group pull-right">
      <a href="/devices" class="btn btn-default" onclick={cancelEdit}>
        <span class="fa fa-remove-circle"></span> Cancel</a>
    </div>
    <h1 if={!device.customName} class="text-center">{device.name}</h1>
    <h1 if={device.customName} class="text-center">
      {device.customName} <small>{device.name}</small>
    </h1>
  </div>

  <div class="container" if={device}>
    <div class="row">

      <div class="col-md-6">
        <!-- ██╗      █████╗ ███████╗████████╗ -->
        <!-- ██║     ██╔══██╗██╔════╝╚══██╔══╝ -->
        <!-- ██║     ███████║███████╗   ██║    -->
        <!-- ██║     ██╔══██║╚════██║   ██║    -->
        <!-- ███████╗██║  ██║███████║   ██║    -->
        <!-- ╚══════╝╚═╝  ╚═╝╚══════╝   ╚═╝    -->

        <div class="panel panel-primary" if={device.tracking}>
          <div class="panel-heading">
            <h3 class="panel-title">Last tracking data
              <a nolink href="#" class="pull-right"
                data-toggle="popover"
                data-title="About last tracking data"
                data-content="Sometimes you can see two different dates, one for GPS and one for battery. This is because the tracker continues to send battery data to the server when no GPS signal is found, so only the battery date is updated and GPS keeps the previous date."
                data-placement="right"
              ><i class="fa fa-info-circle"></i></a></h3>
          </div>
          <div class="panel-body">
            <table class="table table-bordered" if={device.tracking}>
              <tbody>
                <tr>
                  <th>Battery
                  <a nolink href="#" class="pull-right"
                    data-toggle="popover"
                    data-title="Internal battery info"
                    data-content="Battery range is between 3600 (0% charge) and 4100 (100% charge). There may be differences between different batteries due to the nature of lithium batteries."
                    data-placement="right"
                  ><i class="fa fa-info-circle"></i></a>
                  </th>
                  <td>{device.tracking.data.battery}mv
                    <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.servertime).format('DD-MM-YYYY HH:mm:ss')}">
                      {window.moment(device.tracking.servertime).fromNow() || ''}</small>
                  </td>
                </tr>
                <tr>
                  <th>Ext. Battery
                  <a nolink href="#" class="pull-right"
                    data-toggle="popover"
                    data-title="External battery info"
                    data-content="The range will depend on the external battery used by the end user. A value below 6000 (mV) may indicate that no external battery is connected."
                    data-placement="right">
                  <i class="fa fa-info-circle"></i></a>
                  </th>
                  <td if={device.tracking.data.extbattery}>{device.tracking.data.extbattery}mv <small>(vsys: {device.tracking.data.vsys}mv)</small>
                    <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.servertime).format('DD-MM-YYYY HH:mm:ss')}">
                      {window.moment(device.tracking.servertime).fromNow() || ''}</small>
                  </td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td if={device.tracking.data.loc}><a target="_blank" data-toggle="tooltip" title="View location on Open Street Map (external link)"   href="https://www.openstreetmap.org/?mlat={device.tracking.data.loc[1]}&mlon={device.tracking.data.loc[0]}&zoom=16">
                    {device.tracking.data.loc[1]}, {device.tracking.data.loc[0]}</a>
                    <small class="pull-right text-mute" data-toggle="tooltip" title="{window.moment(device.tracking.gpstime).format('DD-MM-YYYY HH:mm:ss')}">
                      {window.moment(device.tracking.gpstime).fromNow()}</small>
                  </td>
                  <td if={!device.tracking.data.loc}>-</td>
                </tr>
                <tr>
                  <th>GPS (<a href="https://en.wikipedia.org/wiki/Dilution_of_precision_(navigation)#Meaning_of_DOP_Values">HDOP</a>)</th>
                  <td if={device.tracking.data.gps}>{device.tracking.data.gps}
                  </td>
                  <td if={!device.tracking.data.gps}>-</td>
                </tr>
                <tr if={device.tracking.data.sats}>
                  <th>Sats
                    <a nolink href="#" class="pull-right"
                    data-toggle="popover"
                    data-title="Number of satellites"
                    data-content="The number of GPS satellites. At least 4 satellites are required for a GPS fix but the signal could be poor. More than 8 satellites can offer quite accurate precission. As of 2018 the <a href='https://tycho.usno.navy.mil/gpscurr.html'>GPS constellation</a> consists of 32 satellites, not all in the same hemisphere as you."
                    data-placement="right"
                  ><i class="fa fa-info-circle"></i></a>
                  </th>
                  <td>{device.tracking.data.sats}</td>
                </tr>
                <tr if={device.tracking.data.gsm}>
                  <th>GSM
                  <a nolink href="#" class="pull-right"
                    data-toggle="popover"
                    data-title="GSM Signal Quality"
                    data-content="A numnber between 1 (poor) and 30 (excellent) to indicate the quality of the GSM signal"
                    data-placement="right"
                  ><i class="fa fa-info-circle"></i></a>
                  </th>
                  <td>{device.tracking.data.gsm}</td>
                </tr>
                <tr>
                  <th>Altitude (meters)</th>
                  <td if={device.tracking.data.alt}>{parseInt(device.tracking.data.alt)}</td>
                  <td if={!device.tracking.data.alt}>-</td>
                </tr>
                <tr if={device.tracking.data.sog}>
                  <th>Speed (km/h)</th>
                  <td>{device.tracking.data.sog}</td>
                </tr>
                <tr if={device.tracking.data.cog}>
                  <th>Direction</th>
                  <td>{device.tracking.data.cog}º
                    <span style="transform: rotate({device.tracking.data.cog}deg); display: inline-block; float: right">&#8648;</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <!-- ███████╗███████╗███╗   ██╗███████╗██╗███╗   ██╗ ██████╗  -->
        <!-- ██╔════╝██╔════╝████╗  ██║██╔════╝██║████╗  ██║██╔════╝  -->
        <!-- ███████╗█████╗  ██╔██╗ ██║███████╗██║██╔██╗ ██║██║  ███╗ -->
        <!-- ╚════██║██╔══╝  ██║╚██╗██║╚════██║██║██║╚██╗██║██║   ██║ -->
        <!-- ███████║███████╗██║ ╚████║███████║██║██║ ╚████║╚██████╔╝ -->
        <!-- ╚══════╝╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝  -->

        <div class="panel panel-primary" if={device.sensing}>
          <div class="panel-heading">
            <h3 class="panel-title">Last sensing data
              <a nolink href="#" class="pull-right"
                data-toggle="popover"
                data-title="About last tracking data"
                data-content="Sometimes you can see two different dates, one for GPS and one for battery. This is because the tracker continues to send battery data to the server when no GPS signal is found, so only the battery date is updated and GPS keeps the previous date."
                data-placement="right"
              ><i class="fa fa-info-circle"></i></a></h3>
          </div>
          <div class="panel-body">
            <table class="table table-bordered" if={device.sensing.time}>
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>{window.moment(device.sensing.time).fromNow()}</td>
                <tr>
                <tr each={value,key in device.sensing.data}>
                  <th>{key}</th>
                  <td>{value}</td>
                </tr>
              </tbody>
            </table>

            <div if={!device.sensing.time}>
              <p>Yet no sensing data :(</p>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">

        <!-- ███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗ -->
        <!-- ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝ -->
        <!-- ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗ -->
        <!-- ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║ -->
        <!-- ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║ -->
        <!-- ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝ -->

        <div class="panel panel-primary" if={device.status && device.status.version.match('^0.3|0.4')}>
          <div class="panel-heading">
            <h3 class="panel-title">Device Status - Power ON/OFF</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <h4>Current Status</h4>
                <span if={status === null}>loading</span>
                <div if={status}>
                  <span if={status.alive === false}>NOT CONNECTED</span>
                  <span if={status.alive === true}>
                    Connected to the network<br />
                    <span class="label label-success" if={status.status === 1} title="The device is connected and enabled. Press button OFF to disable">power on</span>
                    <span class="label label-danger" if={status.status === 0} title="The device is connected and disabled. Press button ON to enable">power off</span>
                    <span class="label label-warning" if={status.status === -1} title="The device is connected to the gateway but status ON/OFF is unknow">unknow</span>
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <h4>Change Status</h4>
                <button onclick={cmdOn} class="btn btn-success" type="button">ON</button>
                <button onclick={cmdOff} class="btn btn-danger" type="button">OFF</button>
              </div>
            </div>
            <div class="input-group hide" id="custom-cfg">
              <input type="text" class="form-control" id="cmd-input">
              <span class="input-group-btn">
                <button class="btn btn-default" type="button" onclick={customCmd}>Send</button>
              </span>
            </div>
          </div>
        </div>

        <!-- ███╗   ███╗███████╗████████╗ █████╗ ██████╗  █████╗ ████████╗ █████╗  -->
        <!-- ████╗ ████║██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗ -->
        <!-- ██╔████╔██║█████╗     ██║   ███████║██║  ██║███████║   ██║   ███████║ -->
        <!-- ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║  ██║██╔══██║   ██║   ██╔══██║ -->
        <!-- ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║██████╔╝██║  ██║   ██║   ██║  ██║ -->
        <!-- ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝ -->

        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title">Device metadata</h3>
          </div>
          <div class="panel-body">
            <form data-toggle="validator" class="form">
              <div class="form-group col-md-6">
                <label for="_id">ID/IMEI</label>
                <input class="form-control" id="_id" value="{device._id}" type="text" required disabled="disabled">
              </div>
              <div class="form-group col-md-6">
                <label for="name">Internal name</label>
                <input class="form-control" id="imei" value="{device.name}" type="text" required disabled="name">
                <div class="help-block with-errors"></div>
              </div>

              <div class="form-group col-md-6">
                <label for="customName">Customer Name</label>
                <input class="form-control" id="customName" value="{device.customName}" type="text">
                <div class="help-block with-errors"></div>
              </div>

              <div class="form-group col-md-3">
                <label for="color">Color</label>
                <input class="form-control" id="color" value="{device.color}" type="color">
                <div class="help-block with-errors"></div>
              </div>

              <div class="form-group col-md-3">
                <label for="color">&nbsp;</label>
                <p><button class="btn btn-primary" id="btn-save" data-text-loading="Saving..." onclick={save}>Save</button></p>
              </div>

              <div class="form-group" if={window.IS_ADMIN}>
                <label for="name">Freeze</label>
                <select id="freeze" class="form-control">
                  <option value="0" selected={device.freeze}>No</option>
                  <option value="1" selected={device.freeze}>Yes</option>
                </select>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-3">

        <!-- ████████╗██████╗  █████╗  ██████╗██╗  ██╗██╗      ██████╗  ██████╗  -->
        <!-- ╚══██╔══╝██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██║     ██╔═══██╗██╔════╝  -->
        <!--    ██║   ██████╔╝███████║██║     █████╔╝ ██║     ██║   ██║██║  ███╗ -->
        <!--    ██║   ██╔══██╗██╔══██║██║     ██╔═██╗ ██║     ██║   ██║██║   ██║ -->
        <!--    ██║   ██║  ██║██║  ██║╚██████╗██║  ██╗███████╗╚██████╔╝╚██████╔╝ -->
        <!--    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝  -->

        <div class="panel panel-primary" if={device && device.hasOwnProperty('tracking')}>
          <div class="panel-heading">
            <h3 class="panel-title">Tracklog
              <a nolink href="#"
                  data-toggle="popover"
                  data-title="Tracklog"
                  data-content="Select a date rage from/to to load the tracking data for this device. Use the dropdown menu on each input to select a fixed date (hours/days ago) or press the calendar icon to select any date."
                  data-placement="right"
                ><i class="fa fa-info-circle"></i></a>
            </h3>
          </div>
          <div class="panel-body">
            <form class="form-inline">
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-from'>
                  <input type='text' class="form-control width-auto" placeholder="from" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-to'>
                  <input type='text' class="form-control width-auto" placeholder="to" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <button type="button" class="btn btn-primary btn-sm" data-text-loading="wait..." onclick={loadTrackingData}>Load</button>
            </form>
          </div>
        </div> <!-- .panel.tracking -->

        <div class="panel panel-primary" if={device && device.sensing}>
          <div class="panel-heading">
            <h3 class="panel-title">Sensing Log
              <a nolink href="#"
                  data-toggle="popover"
                  data-title="Sensing Log"
                  data-content="Select a date rage from/to to load the tracking data for this device. Use the dropdown menu on each input to select a fixed date (hours/days ago) or press the calendar icon to select any date."
                  data-placement="right"
                ><i class="fa fa-info-circle"></i></a>
            </h3>
          </div>
          <div class="panel-body">
            <form class="form-inline">
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-from'>
                  <input type='text' class="form-control width-auto" placeholder="from" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-to'>
                  <input type='text' class="form-control width-auto" placeholder="to" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <button onclick={loadSensingData} type="button" class="btn btn-primary btn-sm" data-text-loading="wait...">Load</button>
            </form>
          </div>
        </div> <!-- .panel.sensing -->
      </div> <!-- .col-md-12 -->

      <div if={sensing || tracking}>
        <!-- ███████╗███████╗███╗   ██╗███████╗██╗███╗   ██╗ ██████╗ ██╗      ██████╗  ██████╗  -->
        <!-- ██╔════╝██╔════╝████╗  ██║██╔════╝██║████╗  ██║██╔════╝ ██║     ██╔═══██╗██╔════╝  -->
        <!-- ███████╗█████╗  ██╔██╗ ██║███████╗██║██╔██╗ ██║██║  ███╗██║     ██║   ██║██║  ███╗ -->
        <!-- ╚════██║██╔══╝  ██║╚██╗██║╚════██║██║██║╚██╗██║██║   ██║██║     ██║   ██║██║   ██║ -->
        <!-- ███████║███████╗██║ ╚████║███████║██║██║ ╚████║╚██████╔╝███████╗╚██████╔╝╚██████╔╝ -->
        <!-- ╚══════╝╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝  -->

        <div id="tracking-data" class="col-md-7" if={tracking}>
          <table class="table table-hover table-bordered table-condensed small" if={tracking.length}>
            <thead>
              <tr>
                <th>Time</th>
                <th>Location</th>
                <th>Speed</th>
                <th>GPS</th>
                <th>Satellites</th>
                <th>GSM</th>
                <th>Battery</th>
                <th>Ext. Battery</th>
                <th>Vsys5V</th>
              </tr>
            </thead>
            <tbody>
              <tr each={p in tracking} data-location="{p.data.loc[1]},{p.data.loc[0]}" onmouseover={setMarker} onclick={centerMarker}>
                <td if={p.gpstime > 0}>{window.moment(p.gpstime).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td if={p.gpstime <  1}>{window.moment(p.servertime).format('DD-MM-YYYY HH:mm:ss')}
                  <small style="font-size: 7px">(server)</small></td>
                <td>{p.data.loc[1]}, {p.data.loc[0]}</td>
                <td>{p.data.speed}</td>
                <td>{p.data.gps}</td>
                <td>{p.data.sats}</td>
                <td>{p.data.gsm}</td>
                <td>{p.data.battery}</td>
                <td>{p.data.extbattery || 0}</td>
                <td>{p.data.vsys || 0}</td>
              </tr>
            </tbody>
          </table>

          <div if={!tracking.length}>
            <p>No tracking data available</p>
          </div>
        </div>

        <div id="sensing-data" class="col-md-7" if={sensing}>
          <table class="table table-hover table-bordered table-condensed" if={sensing.length}>
            <thead>
              <tr>
                <th>Time {sensing.length}</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              <tr each={s in sensing}>
                <td>{window.moment(s.time).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td>{s.data.raw}</td>
              </tr>
            </tbody>
          </table>

          <div if={!sensing.length}>
            <p>No sensing data available</p>
          </div>
        </div>

        <div class="col-md-5" if={tracking && tracking.length}>
          <!-- -->
          <div id="map-canvas" class="mb-3"></div>
          <p <small>only points with geolocation are displayed</small></p>

          <!-- Nav tabs -->
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active"><a href="#tab-battery" aria-controls="tab-battery" role="tab" data-toggle="tab">Battery</a></li>
            <li role="presentation"><a href="#tab-battery-ext" aria-controls="tab-battery-ext" role="tab" data-toggle="tab">Battery Ext.</a></li>
            <li role<="presentation"><a href="#tab-gps" aria-controls="tab-gps" role="tab" data-toggle="tab">GPS</a></li>
            <li role="presentation"><a href="#tab-altitude" aria-controls="tab-altitude" role="tab" data-toggle="tab">Altitude</a></li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active" id="tab-battery">
              <h4>Internal Battery Usage</h4>
              <canvas class="charts" id="chart-battery"></canvas>
            </div>
            <div role="tabpanel" class="tab-pane" id="tab-battery-ext">
              <h4>External Battery Usage</h4>
              <canvas class="charts" id="chart-battery-ext"></canvas>
            </div>
            <div role="tabpanel" class="tab-pane" id="tab-gps">
              <h4>GPS Signal Quality (HDOP)</h4>
              <canvas class="charts" id="chart-gps"></canvas>
              <p><small>only points with geolocation are displayed</small></p>
            </div>
            <div role="tabpanel" class="tab-pane" id="tab-altitude">
              <h4>Altitude</h4>
              <canvas class="charts" id="chart-alt"></canvas>
              <p><small>only points with geolocation are displayed</small></p>
            </div>
          </div>
        </div>

      </div>

    </div> <!-- .row -->
  </div> <!-- .container -->


  <script>
    import {fetch} from '../utils/utils'
    import {datetime2timestamp} from '../utils/utils'
    import {transmitCmd} from './transmit-cmd'
    this.colors = require('../utils/colors')

    this.device = null
    this.tracking = null
    this.sensing = null
    this.status = null

    window.deviceEdit = this

    const charts = {
      'chart-battery': {
        datasets: [{
          label: 'millivolts',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-battery-ext': {
        datasets: [{
          label: 'millivolts',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-gps': {
        datasets: [{
          label: 'GPS',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-alt': {
        datasets: [{
          label: 'Altitude',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      }
    }

    var hasChanged = false
    $(this.root).on('change keyup', 'input, select, textarea', function() {
      hasChanged = true
    })

    this.loadDevice = () => {
      fetch({
        endpoint: `/v1/devices/${opts._id}?select=**`,
        update: false,
        callback: (data) => {
          this.device = data
          this.device.alive = false
          this.update()
          this.isAlive()
        }
      })()
    }

    this.cancelEdit = (e) => {
      if (!hasChanged) return

      e.preventDefault()
      window.swal({
        title: 'Are you sure?',
        text: 'You have unsaved changes!',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Ok, keep editing',
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Yes, go back',
        closeOnConfirm: false
      },
      function() {
        document.location.href = '/devices'
      })
    }

    this.save = (e = {}) => {
      e.preventUpdate = true
      e.preventDefault()
      const data = {
        customName: $('#customName').val().trim() || '',
        color: $('#color').val().trim() || 'FF0000',
        freeze: $('#freeze').val() === '1'
      }

      fetch({
        endpoint: `/v1/devices/${this.device._id}`,
        data: data,
        update: false,
        button: window.$('#btn-save'),
        type: 'put',
        callback: (data) => {
          hasChanged = false
          window.swal('Metadata saved', 'Device info has been updated', 'success')
          this.update();
        }
      })()
    }

    this.loadTrackingData = (e) => {
      e.preventUpdate = true

      const $button = window.$(e.target)
      $button.button('loading')

      const from = datetime2timestamp($('#date-from').data('date'))
      const to = datetime2timestamp($('#date-to').data('date'))
      let limit = 0

      if (!from || !to) {
        limit = 1000
      }

      fetch({
        endpoint: `/v1/devices/positions/${this.device._id}/?from=${from}&to=${to}&sort=servertime:desc&limit=${limit}`,
        update: false,
        type: 'get',
        callback: (data) => {
          $button.button('reset')
          this.tracking = data

          let i = 0
          let len = data.length
          for (; i < len; i++) {
            // battery
            const p = data[i]
            charts['chart-battery'].datasets[0].data.push({x: p.servertime, y: p.data.battery})
            let extbattery = p.data.extbattery || p.data.extbatt || p.data.extra || 0
            charts['chart-battery-ext'].datasets[0].data.push({x: p.servertime, y: extbattery})
            if (p.data.loc[0] !== 0 && p.data.loc[1] !== 0) {
              charts['chart-gps'].datasets[0].data.push({x: p.gpstime, y: p.data.gps})
              charts['chart-alt'].datasets[0].data.push({x: p.gpstime, y: p.data.alt})
            }
          }

          this.update();
        }
      })()
    }

    this.loadSensingData = (e) => {
      e.preventUpdate = true

      const $button = window.$(e.target)
      $button.button('loading')

      const from = datetime2timestamp($('#date-sensing-from').data('date')) || 0
      const to = datetime2timestamp($('#date-sensing-to').data('date')) || 0
      console.log('from', from)
      let limit = 0

      if (!from || !to) {
        limit = 1000
      }

      fetch({
        endpoint: `/v1/devices/sensing/${this.device._id}/?from=${from}&to=${to}&sort=time:desc&limit=${limit}`,
        update: false,
        type: 'get',
        callback: (data) => {
          $button.button('reset')
          this.sensing = data

          let i = 0
          let len = data.length
          for (; i < len; i++) {
            // battery
            const p = data[i]
            charts['chart-battery'].datasets[0].data.push({x: p.servertime, y: p.data.battery})
            let extbattery = p.data.extbattery || p.data.extbatt || p.data.extra || 0
            charts['chart-battery-ext'].datasets[0].data.push({x: p.servertime, y: extbattery})
          }

          this.update();
        }
      })()
    }

    this.cmdOn = (e) => {
      e.preventUpdate = true

      const cmd = {
        cmd: `#IO6|ON$`,
        cache: 1
      }

      transmitCmd(this.device._id, cmd, (err, sent) => {
        if (sent) {
          this.status.alive = true
          this.status.status = 1
        } else {
          this.status.alive = false
          this.status.status = -1
        }

        this.update()
      })
    }

    this.cmdOff = (e) => {
      e.preventUpdate = true
      const cmd = {
        cmd: `#IO6|OFF$`,
        cache: 1
      }
      transmitCmd(this.device._id, cmd, (err, sent) => {
        if (sent) {
          this.status.alive = true
          this.status.status = 0
        } else {
          this.status.alive = false
          this.status.status = -1
        }

        this.update()
      })
    }

    this.customCmd = (e) => {
      e.preventUpdate = true
      const cmd = {
        cmd: `#${$('#cmd-input').val()}$`,
        cache: 0
      }
      transmitCmd(this.device._id, cmd)
    }

    const renderChart = (canvasEl, label) => {
      let ctx = document.getElementById(canvasEl).getContext('2d')
      let cfg = getChartOptions(label, canvasEl)
      let chart = new Chart(ctx, cfg);
    }

    this.on('mount', this.loadDevice)
    this.on('mount', this.isAlive)

    this.on('updated', () => {
      setupDatepickers()
      if (!this.tracking || !this.tracking.length) return
      renderChart('chart-battery', 'Battery mv')
      renderChart('chart-battery-ext', 'Battery Ext. mv')
      renderChart('chart-gps', 'GPS Signal (HDOP)')
      renderChart('chart-alt', 'Altitude')
      renderMap()
    })

    const setupDatepickers = () => {
      if (!$(this.root).find('#date-from').length) {
        // si el dispositivo todavía no tiene datos no se creará la caja
        // de tracklog/sensinglog
        return false
      }
      // setup dates
      const format = 'DD-MM-YYYY HH:mm:ss'
      const icons = {
        time: 'fa fa-time',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }

      $(this.root).find('#date-from').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-to').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-from').data("DateTimePicker").maxDate(moment())
      $(this.root).find('#date-to').data("DateTimePicker").maxDate(moment())

      // link dates from/to
      $(this.root).find("#date-from").on("dp.change", function (e) {
        $('#date-to').data("DateTimePicker").minDate(e.date)
      })
      $(this.root).find("#date-to").on("dp.change", function (e) {
        $('#date-from').data("DateTimePicker").maxDate(e.date)
      })
    }

    const validateFromToDates = (from, to) => {
      let err = ''
      if (!from) err += 'From date is invalid. '
      if (!to) err += 'To date is invalid. '
      if (err != '') return swal(err)
    }

    const getChartOptions = (label, chart) => {
      return {
        type: 'line',
        data: {
          labels: [],
          datasets: charts[chart].datasets
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              type: "time",
              display: true,
              time: {
                displayFormats: {
                 'millisecond': 'MMM DD',
                 'second': 'MMM DD',
                 'minute': 'MMM DD',
                 'hour': 'MMM DD',
                 'day': 'MMM DD',
                 'week': 'MMM DD',
                 'month': 'MMM DD',
                 'quarter': 'MMM DD',
                 'year': 'MMM DD',
                }
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: label
              }
            }]
          }
        }
      }
    }

    const renderMap = () => {
      const MAPLINK = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
      const LATLNG = [0, 0]
      const ZOOM = 18
      const DEBUG = false

      if (!this.map) {
        this.map = L.map('map-canvas').setView(LATLNG, 13)

        L.tileLayer(
          '//cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
          attribution: '&copy; ' + MAPLINK + ' Contributors',
          maxZoom: 20,
        }).addTo(this.map)

        this.marker = L.marker([0, 0], {
          icon: L.icon({
            iconUrl: '/images/marker.png',
            iconSize: [24, 24],
            iconAnchor: [12, 20]
          })
        }).addTo(this.map);
      }

      // polyline
      const latlngs = []

      // buscamos los puntos del tracklog que contienen información de Geolocalización
      let i = 0
      let len = this.tracking.length
      for (; i < len; i++) {
        const point = this.tracking[i]
        if (point.data.loc[0] !== 0, point.data.loc[1] !== 0) {
          latlngs.push(point.data.loc.reverse())
        }
      }

      if (!latlngs.length) {
        this.marker.setOpacity(0)
        // no se ha cargado ningún punto con información de geolocalización,
        // no podemos renderizar un polyline, salimos...
        return
      }

      if (!this.polyline) {
        // si es la primera vez que se carga un tracklog creamos el polyline...
        this.polyline = L.polyline(latlngs, {color: 'red'}).addTo(this.map);
      } else {
        // ...si no lo actualizamos
        this.polyline.setLatLngs(latlngs)
      }

      // ajustamos el mapa al polyline
      this.map.fitBounds(this.polyline.getBounds());
    }

    this.setMarker = (e) => {
      e.preventUpdate = true
      // aunque el evento mouseover está sobre el <tr> riotjs nos devuelve en
      // el evento `e` un child td, para corregir esto utilizamos `parent()`
      const $tr = window.$(e.target).parent()
      const location = $tr.data('location').split(',')
      this.marker.setLatLng(location)
    }

    this.isAlive = () => {

      fetch({
        endpoint: `/v1/devices/isalive/${this.device._id}`,
        update: false,
        callback: (data) => {
          this.status = data
          this.update()
        },
        error: (err) => console.log(err)
      })()
    }

    this.updateStatus = (alive, status) => {

    }

  </script>

  <style>
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none
        margin: 0
    }

    .select2-container {
      width: 100% !important
    }

    .width-auto {
      width: auto !important;
    }

    .input-group-sm {
      display: block;
    }

    .charts {
      width: 100%;
      heigt: 120px;
    }

    #map-canvas {
      width: 100%;
      height: 300px;
    }

    #tracking-data {
      max-height: 900px;
      overflow: auto;
    }

    td {
      word-break: break-all;
    }
  </style>

</devices-user-edit>
