<maps-edit>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <button type="button" class="btn btn-primary" onclick={save}>
        <span class="fa fa-save"></span> Save</button>
      <a href="/maps" class="btn btn-default" id="btn-cancel" onclick={cancelEdit}>
        <span class="fa fa-remove-circle"></span> Cancel</a>
    </div>
    <h1>Edit map</h1>
  </div>

  <div class="container-fluid" if={loading}>
    <div class="row">
      <div class="col-md-12">
        <p>Loading map data, please wait...</p>
      </div>
    </div>
  </div>

  <div id="map-main" class="container-fluid" if={!loading} style="opacity: 0">
    <div class="row">
      <div class="col-md-12">
        <form data-toggle="validator" role="form" class="form">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
            <li role="presentation" class="active"><a href="#meta" aria-controls="meta" role="tab" data-toggle="tab">Meta</a></li>
            <li role="presentation"><a href="#devices" aria-controls="devices" role="tab" data-toggle="tab">Devices &amp; Categories</a></li>
            <li role="presentation"><a href="#members" aria-controls="members" role="tab" data-toggle="tab">Members</a></li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content tab-content-custom">
            <div role="tabpanel" class="tab-pane active" id="meta">
              <maps-edit-meta map="{opts}"></maps-edit-meta>
            </div>

            <div role="tabpanel" class="tab-pane" id="devices">
              <maps-edit-devices map="{opts}"></maps-edit-devices>
              <maps-edit-categories map="{opts}"></maps-edit-categories>
            </div>

            <div role="tabpanel" class="tab-pane" id="members">
              <maps-edit-members map={opts}></maps-edit-members>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <script>
    const riot = require('riot')
    const utils = require('../utils/utils')

    this.loading = true

    $('form').validator()
    let hasChanged = false
    $(this.root).on('change keyup', 'input, select, textarea', function() {
      hasChanged = true
    })

    this.loadMapData = () => {
      const query = [
        'select=*',
        'populate=_categories;_account;_devices;model=Account,path=members._account'
      ].join('&')

      return utils.fetch({
        url: window.__CONF.apiUrl + '/v1/iot/' + opts._id + '?' + query,
        update: false,
        callback: (map) => {
          console.log('map', map)
          map.raw = map.raw || {}

          try {
            map.raw = JSON.stringify(map.raw, null, 2)
          } catch (e) {
            callback(e)
          }

          this.opts = map
          this.getDeviceCategory()
          this.loading = false
          this.update()

          utils.tabsStatus(this, 'maps')
          window.$('#map-main').animate({opacity: 1})

          this.trigger('map-loaded')
        }
      })()
    }

    this.getDeviceCategory = (deviceId) => {
      let i = this.opts._categories.length
      while (i--) {
        const category = this.opts._categories[i]
        const devices = category._devices
        if (devices.indexOf(deviceId) > -1) {
          return category._id
        }
      }

      return null
    }

    this.save = () => {
      const data = {
        title: window.$('#title').val(),
        slug: window.$('#slug').val(),
        private: window.$('#private').is(':checked'),
        conf: this.opts.conf,
        allowed: this.opts.allowed,
        conf: {
          loc: [window.$('#lon').val(), window.$('#lat').val()],
          zoom: window.$('#zoom').val()
        }
      }

      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot/' + opts._id,
        update: false,
        data: data,
        type: 'put',
        callback: function() {
          sweetAlert('Map saved')
        },
        error: function () {
          sweetAlert('Opps...', 'Something went wrong!', 'error')
        }
      }

      utils.fetch(fetchOptions)()
    }

    this.on('mount', this.loadMapData)
  </script>

  <style>
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .select2-container {
      width: 100% !important;
    }
  </style>

</maps-edit>
