// socket
module.exports = () => {
  const accountId = window.__USER._id

  const socket = window.io.connect('https://watcher.m2msystemsource.com/' + accountId)

  socket.on('connect', () => console.log('io-connect'))

  const ioEventPosition = (data) => window.emitter.trigger('position', data)
  socket.on('position', ioEventPosition)

  const ioAck = (data) => window.emitter.trigger('ack', data)
  socket.on('ack', (data) => ioAck)

  socket.on('gwtcp2/login', (data) => {
    window.emitter.trigger('gwtcp2/login')
  })

  window.socket = socket
}
