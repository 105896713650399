<maps-edit-devices>
  <div class="pull-right">
    <button type="button" class="btn btn-link btn-sm"
      title="Map Devices"
      target="_blank"
      data-toggle="popover"
      data-placement="left"
      data-html="true"
      data-content="This devices will be visible on the map. You can add more devices using the input search (top-right). The total available devices are listed on the devices section.">
      <span class="fa fa-info-circle"></span>
    </button>
  </div>

  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Devices</h3>
    </div>
    <div class="panel-body">

      <div class="form-group">
        <select id="search-device" class="form-control input-sm" placeholder="search"></select>
      </div>

      <table class="table table-bordered table-condensed table-hover">
        <thead>
          <tr>
            <th>Device</th>
            <th>Icon</th>
            <th>Category</th>
            <th style="width: 30px"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="parent-showing" each={device in opts.map._devices}>
            <td style="vertical-align: middle">{device.name}</td>
            <td>
              <span
                class="icon-map-ball"
                style="background-color: {device.color || '#ff0000'}; border-color: {device.color || '#ff0000'}">
              </span>
            </td>
            <td>
              <virtual each={category in findDeviceCategoriesNames(device._id)}>
                <span  class="label label-default">{category}</span>
              </virtual>
            </td>
            <td class="adjust-cell">
              <a href="/devices/{device._id}/edit" class="btn btn-success btn-xs children-hidden" data-toggle="tooltip" title="Edit device">
                <span class="fa fa-share-square-o"></span></a>
              <button onclick={removeFromMap} data-id={device._id} type="button" class="btn btn-danger btn-xs children-hidden" data-toggle="tooltip" tile="Remove this device from this map">
                <span class="fa fa-remove"></span></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <maps-manage-devices map={opts.map}></maps-manage-devices>
  </div>

  <script>
    var utils = require('../utils/utils')
    var that = this
    var parent = this.parent

    this.isAdded = (deviceId) => {
      let i = 0
      const len = that.opts.map._devices.length
      for (;i < len; i++) {
        const device = that.opts.map._devices[i]
        if (device._id === deviceId) {
          return true
        }
      }

      return false
    }

    this.initSelect2 = () => {
      if (window.$('#search-device').data('select2')) {
        return;
      }

      window.$('#search-device').select2({
        theme: 'bootstrap',
        placeholder: 'Add Device',
        ajax: {
          url: window.__CONF.apiUrl + '/v1/devices/',
          headers: {
            'Authorization': 'Basic ' + window.Cookies.get('m2mss-login')
          },
          dataType: 'json',
          type: 'get',
          delay: 250,
          data: function (params) {
            if (params.term) {
              return {search: 'name:' + params.term}
            }
            return {}
          },
          processResults: (data, params) => {
            var select2Data = []
            var i = data.length

            while (i--) {
              var item = data[i]
              if (!this.isAdded(item._id)) {
                select2Data.push({
                  id: item._id,
                  _id: item._id,
                  text: item.name,
                  name: item.name
                })
              }
            }
            return {results: select2Data}
          },
          cache: true
        },
        //minimumInputLength: 1,
        escapeMarkup: function (markup) {return markup},
        templateResult: (item) => {
          if (item.loading) return item.text
          return '<div>' + item.name + '</div>'
        },
        templateSelection: (item) => {
          return item.name || item.text
        }
      })

      window.$('#search-device').on('select2:select', this.setSelect2Selection.bind(this))
    }

    this.setSelect2Selection = (event) => {
      window.$('#search-device').val('').trigger('change');
      var data = event.params.data
      this.add(data)
    }

    this.add = (data) => {
      var fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot/' + this.opts.map._id + '/device',
        type: 'post',
        update: false,
        data: {
          _device: data._id
        },
        callback: function(data) {
          console.log('data', data)
          that.opts.map._devices = data._devices
          that.update()
          that.parent.update()
        }
      }

      utils.fetch(fetchOptions)()
    }

    this.findDeviceCategoriesNames = (deviceId) => {
      var names = []
      var categories = this.opts.map._categories
      var i = categories.length
      while (i--) {
        if (categories[i]._devices.indexOf(deviceId) > -1) {
          names.push(categories[i].name)
        }
      }
      return names
    }

    this.removeFromMap = (e) => {
      const deviceId = $(e.currentTarget).data('id')
      const fetchOptions = {
        url: window.__CONF.apiUrl + '/v1/iot/' + this.opts.map._id + '/device',
        type: 'delete',
        update: false,
        data: {
          _device: deviceId
        },
        callback: function(data) {
          that.opts.map._categories = data._categories
          that.opts.map._devices = data._devices
          that.update()
          that.parent.update()
        }
      }
      utils.fetch(fetchOptions)()
    }

    this.on('mount', this.initSelect2)

    parent.on('category-update', function() {
      that.update()
    })

  </script>
</maps-edit-devices>
