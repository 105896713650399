<device-last-sensing>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Last Sensing Data
        <a nolink href="#" class="pull-right"
          data-toggle="popover"
          data-title="About last tracking data"
          data-content="Sometimes you can see two different dates, one for GPS and one for battery. This is because the tracker continues to send battery data to the server when no GPS signal is found, so only the battery date is updated and GPS keeps the previous date."
          data-placement="right"
        ><i class="fa fa-info-circle"></i></a></h3>
    </div>
    <div class="panel-body">
      <table class="table table-bordered" if={device.sensing.time}>
        <tbody>
          <tr>
            <th>Date</th>
            <td>{window.moment(device.sensing.time).fromNow()}</td>
          <tr>
          <tr each={value,key in device.sensing.data}>
            <th>{key}</th>
            <td>{value}</td>
          </tr>
        </tbody>
      </table>

      <div if={!device.sensing.time}>
        <p>Yet no sensing data :(</p>
      </div>
    </div>
  </div>
  <div id="sensing-data" class="col-md-7" if={sensing}>
    <table class="table table-hover table-bordered table-condensed" if={data && data.length}>
      <thead>
        <tr>
          <th>Time {sensing.length}</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        <tr each={s in sensing}>
          <td>{window.moment(s.time).format('DD-MM-YYYY HH:mm:ss')}</td>
          <td>{s.data.raw}</td>
        </tr>
      </tbody>
    </table>

    <div if={!sensing.length}>d
      <p>No sensing data available</p>
    </div>
  <script>
    this.device = this.parent.device || this.opts.device
    this.data = null

    this.loadSensingData = (e) => {
      e.preventUpdate = true

      const $button = window.$(e.target)
      $button.button('loading')

      const from = datetime2timestamp($('#date-sensing-from').data('date'))
      const to = datetime2timestamp($('#date-sensing-to').data('date'))
      let limit = 0

      if (!from || !to) {
        limit = 100
      }

      fetch({
        endpoint: `/v1/devices/sensing/${this.device._id}/?from=${from}&to=${to}&sort=servertime:desc&limit=${limit}`,
        update: false,
        type: 'get',
        callback: (data) => {
          $button.button('reset')
          this.sensing = data

          let i = 0
          let len = data.length
          for (; i < len; i++) {
            // battery
            const p = data[i]
            charts['chart-battery'].datasets[0].data.push({x: p.servertime, y: p.data.battery})
            let extbattery = p.data.extbattery || p.data.extbatt || p.data.extra || 0
            charts['chart-battery-ext'].datasets[0].data.push({x: p.servertime, y: extbattery})
          }

          this.update();
        }
      })()
    }
  </script>

  <style>
    td {
      word-break: break-all;
    }
  </style>
</device-last-sensing>
