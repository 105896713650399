<device-sim>

  <div class="panel panel-primary" if={device}>
    <div class="panel-heading">
      <h3 class="panel-title">Device Sim</h3>
    </div>
    <div class="panel-body">
      <div if={sim._id}>
        <ul class="list-unstyled">
          <li>ICCID: {sim.iccid}</li>
        </ul>
        <p>
          <button class="btn btn-default btn-sm" onclick={changeSim}>Change Sims</button>
          <a href="/sims/{sim.iccid}/edit" class="btn btn-default btn-sm pull-right">Edit</a>
        </p>
      </div>

      <div if={!sim._id}>
        <div class="alert alert-danger">This device does not have a SIM assigned to it.</div>
        <button class="btn btn-default btn-sm" onclick={changeSim}>Add Sims</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="change-sim" tabindex="-1" role="dialog" aria-labelledby="change-sim-label">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="change-sim-label">Change Device Sim</h4>
        </div>
        <div class="modal-body">
          <div id="loading" class="hide">loading...</div>

          <p class="lead" if={opts._sim}>Current SIM ICCID: {opts._sim.iccid}</p>
          <p class="lead" if={!opts._sim}>Currently there is no SIM assigned</p>
          <p>
            <span>Fill in the ICCID of the new SIM and press <em>Search</em> button</span>
            <div class="input-group error">
              <input type="text" class="form-control" id="new-sim" placeholder="NEW SIM ICCID">
              <span class="input-group-btn">
                <button class="btn btn-default" type="button" onclick={searchSim}>Search</button>
              </span>
            </div>
          </p>

          <div id="loading" class="hide">
            loading...
          </div>

          <div id="sim-is-free" class="hide">
            <div class="alert alert-info">This sim is available. Want to attach it to this device?</div>
            <p>
              <button class="btn btn-primary" onclick={attachSim}>Yes, Attach now</button>
              <button class="btn btn-primary" onclick={discardAttachSim}>Discard</button>
            </p>
          </div>

          <div id="sim-not-free" class="hide">
            <div class="alert alert-danger">Caution, this sim is attached to other device
              (<span id="old-sim"></span>).
              By continuing this device will be left without an associated SIM.</div>
            <p>
              <button class="btn btn-primary" onclick={attachSim}>I understand, attach now</button>
              <button class="btn btn-primary" onclick={discardAttachSim}>Discard</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils')

    this.device = this.parent.device || this.opts.device
    console.log('this.device', this.device)
    this.sim = this.device._sim || {}
    let newSim;

    /**
     * Abre modal change sim
     * @return {this}
     */
    this.changeSim = (e) => {
      e.preventUpdate = true
      $('#change-sim')
        .modal('show')
        .one('show.bs.modal', this.resetChangeSim)

      return this
    }

    /**
     * Busca la sim que se quiere asignar. La idea es informar del estado de esta
     * sim, si está libreo o no.
     *
     * El input para buscar sim queda bloqueado tras la búsqueda (si la sim existe).
     * Se puede pulsar el botón discard para desbloquear el formulario y realizar
     * una nueva búsqueda
     *
     * @return {sim}
     */
    this.searchSim = (e) => {
      e.preventUpdate = true
      const sim = $('#new-sim').val().trim()
      if (this.opts._sim) {
        if (sim === this.opts._sim.iccid) {
          return sweetAlert('ICCID cannot be the current one', null, 'error')
        }
      }

      $('#loading').removeClass('hide')
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#new-sim').closest('.input-group').removeClass('has-error')

      utils.fetch({
        url: window.__CONF.apiUrl + `/v1/sims/${sim}/free`,
        update: false,
        callback: (simData) => {
          if (simData.device) {
            $('#old-sim').html(simData.device._id)
            $('#sim-not-free').removeClass('hide')
          } else {
            $('#sim-is-free').removeClass('hide')
          }

          // bloqueamos el input de la sim, esto evitará modificaciones en el
          // caso de que se pulse el botón "attach sim" habiendo modificado la SIM
          // que se ha dicho que está ok.
          $('#new-sim').prop('disabled', true)

          // guardamos la sim
          newSim = simData
        },
        fail: () => {
          $('#new-sim').closest('.input-group').addClass('has-error')
        },
        always: () => {
          $('#loading').addClass('hide')
        }
      })()

      return this
    }

    /**
     * Reestablece el formulario para buscar otra sim
     * @return {this}
     */
    this.discardAttachSim = () => {
      $('#loading').addClass('hide')
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#new-sim')
        .val('').prop('disabled', false)
        .closest('.input-group').removeClass('has-error')

      return this
    }

    this.attachSim = () => {
      if (!newSim) return sweetAlert('No sim selected, try again', null, 'error')

      utils.fetch({
        url: window.__CONF.apiUrl + `/v1/devices/${this.device._id}/sim/${newSim._id}`,
        update: false,
        type: 'PUT',
        callback: (providers) => {
          swal({title: 'Device sim updated', type: 'success'}, () => {
            $('#change-sim').modal('hide')
          })

          this.sim = newSim
          this.update()
          // this.parent.update();
        },
        error: function() {
          sweetAlert('Oops!', 'Error, try again', 'error')
        }
      })()
    }

    this.resetChangeSim = () => {
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#loading').addClass('hide')
      $('#new-sim').val('').prop('disabled', false)
    }
  </script>
</device-sim>
