<devices-admin-edit>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <button type="button" class="btn btn-primary" onclick={save}>
        <span class="fa fa-save"></span> Guardar</button>
      <a href="/devices" class="btn btn-default">
        <span class="fa fa-remove-circle"></span> Cancel</a>
    </div>
    <h1>Device {opts.name}</h1>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <h3>Edit info</h3>
        <form data-toggle="validator" class="form">
          <div class="form-group">
            <label for="name">name</label>
            <input class="form-control" id="name" value="{opts.name}"
              type="text"
              required>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="account">account</label>
            <select class="form-control select2" id="account">
              <option></option>
              <option
                each={account in accounts}
                selected={parent.opts._account === account._id}
                value="{account._id}">{account.username}</option>
            </select>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="freeze">freeze</label>
            <select class="form-control" id="freeze">
              <option value="0" selected={!opts.freeze}>no</option>
              <option value="1" selected={opts.freeze}>yes</option>
            </select>
            <div class="help-block with-errors"></div>
          </div>

        </form>
      </div>

      <div class="col-md-4">
        <h3>Sim</h3>
        <div if={opts._sim}>
          <ul class="list-unstyled">
            <li>ICCID: {opts._sim.iccid}</li>
            <li>IMSI: {opts._sim.imsi}</li>
          </ul>
          <p>
            <button class="btn btn-default btn-sm" onclick={loadSimDetails}>Load SIM details</button>
            <button class="btn btn-default btn-sm" onclick={changeSim}>Change Sims</button>
          </p>
        </div>

        <div if={!opts._sim}>
          <div class="alert alert-danger">This device does not have a SIM assigned to it.</div>
          <button class="btn btn-default btn-sm" onclick={changeSim}>Add Sims</button>
        </div>

        <h3>Print Sticker</h3>
        <p><a href="/sticker/{opts._id}">print</a></p>
      </div>

      <div class="col-md-4" if={opts.tracking}>
        <h3>Last positions</h3>
        <div id="last-position">
          <div id="map"></div>
        </div>
      </div>
      <sims-details data={opts._sim}></sims-details>
    </div>
  </div>

  <div class="modal fade" id="change-sim" tabindex="-1" role="dialog" aria-labelledby="change-sim-label">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="change-sim-label">Change Device Sim</h4>
        </div>
        <div class="modal-body">
          <div id="loading" class="hide">loading...</div>

          <p class="lead" if={opts._sim}>Current SIM ICCID: {opts._sim.iccid}</p>
          <p class="lead" if={!opts._sim}>Currently there is no SIM assigned</p>
          <p>
            <span>Fill in the ICCID of the new SIM and press <em>Search</em> button</span>
            <div class="input-group error">
              <input type="text" class="form-control" id="new-sim" placeholder="NEW SIM ICCID">
              <span class="input-group-btn">
                <button class="btn btn-default" type="button" onclick={searchSim}>Search</button>
              </span>
            </div>
          </p>

          <div id="loading" class="hide">
            loading...
          </div>

          <div id="sim-is-free" class="hide">
            <div class="alert alert-info">This sim is available. Want to attach it to this device?</div>
            <p>
              <button class="btn btn-primary" onclick={attachSim}>Yes, Attach now</button>
              <button class="btn btn-primary" onclick={discardAttachSim}>Discard</button>
            </p>
          </div>

          <div id="sim-not-free" class="hide">
            <div class="alert alert-danger">Caution, this sim is attached to other device
              (<span id="old-sim"></span>).
              By continuing this device will be left without an associated SIM.</div>
            <p>
              <button class="btn btn-primary" onclick={attachSim}>I understand, attach now</button>
              <button class="btn btn-primary" onclick={discardAttachSim}>Discard</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    this.opts = this.opts || {}
    this.opts._sim = {}
    const that = this
    const utils = require('../utils/utils')
    const simDetails = require('../sims-admin/sims-details.tag')

    this.accounts = []

    // en el caso que se solicite un cambio de SIM, la nueva sim se almacenará
    // aquí. Esta sim se obtiene al pulsar el botón buscar.
    let newSim = null

    $('form').validator()
    let hasChanged = false
    $(this.root).on('change keyup', 'input, select, textarea', function() {
      hasChanged = true
    })

    this.loadData = () => {
      window.async.parallel({
        device: function (callback) {
          const query = '?populate=_sim'
          const options = {
            url: window.__CONF.apiUrl + '/v1/devices/' + that.opts._id + query,
            update: false,
            callback: function(device) {
              callback(null, device)
            }
          }
          utils.fetch(options)()
        },

        accounts: function (callback) {
          const options = {
            url: window.__CONF.apiUrl + '/v1/accounts/?select=username',
            update: false,
            callback: function(accounts) {
              callback(null, accounts)
            }
          }
          utils.fetch(options)()
        },

        lastPosition: function (callback) {
          const options = {
            url: window.__CONF.apiUrl + '/v1/devices/last-position/' + that.opts._id,
            update: false,
            callback: function(last) {
              last = last || {}
              if (last.gpstime) {
                return callback(null, last)
              }

              return callback(null, false)
            }
          }
          utils.fetch(options)()
        }
      }, function(err, results) {
        if (err) return utils.alertError()
        that.opts = results.device
        that.accounts = results.accounts
        that.last = that.opts.tracking = results.lastPosition

        window.$('.select2').select2({theme: 'bootstrap'})
        that.update()
      })
    },

    // -------------------------------------------------------------------------

    /**
     * Obtiene del formulario la info del dispositivo que se guardará
     * al pulsar botón "guardar"
     * @return {Object} Device data
     */
    this.getDeviceData = () => {
      const data = {
        name: window.$('#name').val(),
        _account: window.$('#account').val(),
        freeze: window.$('#freeze').val() === '1' ? true : false
      }

      return data
    }

    /**
     * Petición ajax a la api para guardar la sim
     * @return {this}
     */
    this.save = (e) => {
      e.preventUpdate = true
      const device = this.getDeviceData()

      utils.fetch({
        url: window.__CONF.apiUrl + '/v1/devices/' + opts._id,
        update: false,
        data: device,
        type: 'PUT',
        callback: function(providers) {
          sweetAlert('Device updated')
        },
        error: function() {
          sweetAlert('Oops!', 'Error, try again', 'error')
        }
      })()

      return this
    }

    // -------------------------------------------------------------------------

    /**
     * Mapa de la ultima posición del dispositivo.
     * Solo se muestra si hay ultima posición
     * @return {this}
     */
    this.setupMap = () => {
      if (this.map) return

      const loc = this.last.data.loc.reverse()
      const MAPLINK = '<a href="http://openstreetmap.org">OpenStreetMap</a>';
      const LATLNG = loc;
      const ZOOM = 18;
      const DEBUG = false;

      this.map = L.map('map').setView(LATLNG, 13);

      L.tileLayer(
        '//cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
        attribution: '&copy; ' + MAPLINK + ' Contributors',
        maxZoom: 20,
      }).addTo(this.map);

      this.marker = L.marker(LATLNG).addTo(this.map)
      this.marker.bindPopup(`
        <ul class="list-unstyled">
          <li>Time: ${this.last.gpstime}</li>
          <li>Location: ${loc}</li>
        </ul>
      `)

      return this
    }

    // -------------------------------------------------------------------------
    // MODAL CAMBIO DE SIM
    //
    // El cambio se realiza en dos pasos, primero se busca la sim que se quiere
    // adjuntar al dispositivo (input + botón search), se muestra su estado
    // (ocupada en otro device o no) y se pulsa el botón attach para finalmente
    // realizar la acción de cambio de sim.
    //
    // Tras pulsar el botón "search", la sim obtenido se guarda en la variable
    // "newSim" (al inicio de este script), y se utiliza esta variable para enviar
    // el id de la sim a la api junto con el id del device tras pulsar el botón
    // attach

    /**
     * Abre modal change sim
     * @return {this}
     */
    this.changeSim = (e) => {
      e.preventUpdate = true
      $('#change-sim')
        .modal('show')
        .one('show.bs.modal', this.resetChangeSim)

      return this
    }

    /**
     * Busca la sim que se quiere asignar. La idea es informar del estado de esta
     * sim, si está libreo o no.
     *
     * El input para buscar sim queda bloqueado tras la búsqueda (si la sim existe).
     * Se puede pulsar el botón discard para desbloquear el formulario y realizar
     * una nueva búsqueda
     *
     * @return {sim}
     */
    this.searchSim = (e) => {
      e.preventUpdate = true
      const sim = $('#new-sim').val().trim()
      if (this.opts._sim) {
        if (sim === this.opts._sim.iccid) {
          return sweetAlert('ICCID cannot be the current one', null, 'error')
        }
      }

      $('#loading').removeClass('hide')
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#new-sim').closest('.input-group').removeClass('has-error')

      utils.fetch({
        url: window.__CONF.apiUrl + `/v1/sims/${sim}/free`,
        update: false,
        callback: (simData) => {
          if (simData.device) {
            $('#old-sim').html(simData.device._id)
            $('#sim-not-free').removeClass('hide')
          } else {
            $('#sim-is-free').removeClass('hide')
          }

          // bloqueamos el input de la sim, esto evitará modificaciones en el
          // caso de que se pulse el botón "attach sim" habiendo modificado la SIM
          // que se ha dicho que está ok.
          $('#new-sim').prop('disabled', true)

          // guardamos la sim
          newSim = simData
        },
        fail: () => {
          $('#new-sim').closest('.input-group').addClass('has-error')
        },
        always: () => {
          $('#loading').addClass('hide')
        }
      })()

      return this
    }

    /**
     * Reestablece el formulario para buscar otra sim
     * @return {this}
     */
    this.discardAttachSim = () => {
      $('#loading').addClass('hide')
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#new-sim')
        .val('').prop('disabled', false)
        .closest('.input-group').removeClass('has-error')

      return this
    }

    this.attachSim = () => {
      if (!newSim) return sweetAlert('No sim selected, try again', null, 'error')

      utils.fetch({
        url: window.__CONF.apiUrl + `/v1/devices/${this.opts._id}/sim/${newSim._id}`,
        update: false,
        type: 'PUT',
        callback: (providers) => {
          swal({title: 'Device sim updated', type: 'success'}, () => {
            $('#change-sim').modal('hide')
          })

          this.opts._sim = newSim
          this.update()
        },
        error: function() {
          sweetAlert('Oops!', 'Error, try again', 'error')
        }
      })()
    }

    this.resetChangeSim = () => {
      $('#sim-not-free').addClass('hide')
      $('#sim-is-free').addClass('hide')
      $('#loading').addClass('hide')
      $('#new-sim').val('').prop('disabled', false)
    }

    // -------------------------------------------------------------------------
    // El modal para ver los detalles de la sim es una vista independiente
    // incluída aquí como childview.
    this.loadSimDetails = (e) => {
      e.preventUpdate = true
      this.trigger('sim-details', this.opts._sim)
    }

    // -------------------------------------------------------------------------
    // init
    this.on('before-mount', this.loadData)
    this.on('updated', () => {this.setupMap()})
  </script>

  <style>
    .select2-container {
      width: 100% !important
    }

    #map {
      width: 100%;
      height: 400px;
    }
  </style>

</devices-admin-edit>
