import {fetch} from '../utils/utils'

/**
 * @param {String} deviceId
 * @param {String} cmd Comando a enviar, iniciar con # y termina con $
 */
export function transmitCmd (deviceId, data, cb) {
  window.swal({
    title: 'Sending command',
    text: 'Please wait',
    type: 'info',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  })

  fetch({
    data,
    type: 'post',
    endpoint: '/v1/cmds/' + deviceId,
    update: false,
    callback: (data) => {
      window.swal.close()

      const swalOpts = {
        title: '',
        type: '',
        showConfirmButton: true,
        allowOutsideClick: true,
        confirmButtonText: 'Tray Again',
        callback: () => cb(null, false)
      }

      const retryCallback = () => {
        window.swal.close()
        setTimeout(() => {
          transmitCmd(deviceId, data)
        }, 310)
      }

      if (data.sent) {
        swalOpts.title = 'Alright! Command sent sucessful'
        swalOpts.type = 'success'
        swalOpts.confirmButtonText = 'Ok'
        swalOpts.callback = () => window.swal.close()
      } else if (data.save) {
        swalOpts.title = 'SAVED! Command has been saved and will be transmited soon'
        swalOpts.type = 'success'
        swalOpts.confirmButtonText = 'Ok'
        swalOpts.callback = () => window.swal.close()
      } else if (data.bussy) {
        swalOpts.title = 'Command FAIL! The server is bussy pocessing other command for this device. Do you want to try again?'
        swalOpts.type = 'error'
        swalOpts.showCancelButton = true
        swalOpts.callback = retryCallback
      } else if (data.alive) {
        swalOpts.title = 'Command NOT sent. The device is alive but not responding. Do you want to try again?'
        swalOpts.type = 'warning'
        swalOpts.callback = retryCallback
        swalOpts.showCancelButton = true
      } else if (!data.alive) {
        swalOpts.title = 'Command NOT sent. The device is not alive. Please try again in a few seconds'
        swalOpts.type = 'error'
        swalOpts.confirmButtonText = 'Ok'
      }

      setTimeout(() => window.swal(swalOpts, swalOpts.callback), 300)
    },
    fail: () => {
      window.swal.close()
    }
  })()
}
