<sims-user-edit>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/sims" class="btn btn-default">
        <span class="fa fa-arrow-left"></span> Back</a>
    </div>
    <h1>View sim</h1>
  </div>

  <div class="container-fluid" if={sim}>
    <p>Sim: {sim.iccid}</p>
  </div>

  <div class="container-fluid" if={sim === null}>
    <p>Loading</p>
  </div>

  <div class="container-fluid" if={sim === false}>
    <p>SIM not found</p>
  </div>


  <script>
    const that = this;
    const utils = require('../utils/utils');

    this.sim = null

    this.loadSim = () => {
      const fetchSimOptions = {
        url: window.__CONF.apiUrl + '/v1/sims/' + opts._id + '?select=*',
        update: false,
        callback: (sim) => {
          this.sim = sim
          console.log('okok')
          this.update()
        }
      }

      utils.fetch(fetchSimOptions)();
    }

    this.on('mount', () => {
      this.loadSim()
    })
  </script>

</sims-user-edit>
