<device-sensinglog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title">Sensing Log
              <a nolink href="#"
                  data-toggle="popover"
                  data-title="Sensing Log"
                  data-content="Select a date rage from/to to load the sensing data for this device. Use the dropdown menu on each input to select a fixed date (hours/days ago) or press the calendar icon to select any date."
                  data-placement="right"
                ><i class="fa fa-info-circle"></i></a>
            </h3>
          </div>
          <div class="panel-body">
            <form class="form-inline">
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-from'>
                  <input type='text' class="form-control width-auto" placeholder="from" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-to'>
                  <input type='text' class="form-control width-auto" placeholder="to" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <input type='text' id="limit" class="form-control input-sm width-auto" placeholder="limit" size="5" value="100" />
              </div>
              <button onclick={loadData} type="button" class="btn btn-primary btn-sm" data-text-loading="wait...">Load</button>
            </form>

            <div class="row mt-2">
              <div if={data && data.length} id="sensing-data" class="col-md-12">
                <div>
                  <span class="label label-default">total: {dataStats.total}</span>
                </div>
                <table class="table table-hover table-bordered table-condensed small mt-2">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr each={p in data}>
                      <td>{window.moment(p.time).format('DD-MM-YYYY HH:mm:ss')}</td>
                      <td>{p.data.raw}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-md-12" if={data === null}>
                <p>Select a date range or press directly the load button</p>
              </div>

              <div class="col-md-12" if={data  && data.length === 0}>
                <p>No sensing data available. Try selecting other date range.</p>
              </div>
            </div>
          </div>
        </div> <!-- .panel.sensing -->
      </div>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'
    import {datetime2timestamp} from '../utils/utils'

    this.data = null
    this.device = this.parent.device || this.opts.device || {}

    this.dataStats = {total: 0}

    this.loadData = (e) => {
      e.preventUpdate = true

      const $button = window.$(e.target)
      $button.button('loading')

      const from = datetime2timestamp($('#date-from').data('date'))
      const to = datetime2timestamp($('#date-to').data('date'))
      let limit = parseInt(window.jQuery('#limit').val()) || 100

      fetch({
        endpoint: `/v1/devices/sensing/${this.device._id}/?from=${from}&to=${to}&sort=time:desc&limit=${limit}`,
        update: false,
        type: 'get',
        callback: (data) => {
          $button.button('reset')
          this.data = data
          this.dataStats.total = data.length
          this.update();
        }
      })()
    }

    const setupDatepickers = () => {
      // setup dates
      const format = 'DD-MM-YYYY HH:mm:ss'
      const icons = {
        time: 'fa fa-time',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }

      $(this.root).find('#date-from').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-to').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-from').data("DateTimePicker").maxDate(moment())
      $(this.root).find('#date-to').data("DateTimePicker").maxDate(moment())

      // link dates from/to
      $(this.root).find("#date-from").on("dp.change", function (e) {
        $('#date-to').data("DateTimePicker").minDate(e.date)
      })
      $(this.root).find("#date-to").on("dp.change", function (e) {
        $('#date-from').data("DateTimePicker").maxDate(e.date)
      })
    }

    const validateFromToDates = (from, to) => {
      let err = ''
      if (!from) err += 'From date is invalid. '
      if (!to) err += 'To date is invalid. '
      if (err != '') return swal(err)
    }

    this.on('updated', () => {
      setupDatepickers()
    })
  </script>

  <style type="text/css">
    width-auto {
      width: auto !important;
    }

    .input-group-sm, .input-sm {
      display: block;
    }

    #sensing-data {
      max-height: 900px;
      overflow: auto;
    }

    td {
      word-break: break-all;
    }
  </style>
</device-sensinglog>
