<sticks>
  <div class="container-fluid container-fluid-header" if={device}>
    <h1 if={!device.customName} class="text-center">{device.name}</h1>
    <h1 if={device.customName} class="text-center">
      {device.customName} <small>{device.name}</small>
    </h1>
  </div>

  <div class="container-fluid" if={this.device}>
    <div class="row">
      <div class="col-md-4 col-md-offset-4 mt-1" style="max-width: 62mm">
        <div class="row sticker">
          <div class="col-md-7 sticker-left">
            <ul class="list-unstyled">
              <li><strong>PN</strong> <input id="qr-pn" class="input-qr" onchange={generateStick} type="text" value="HDP-TRK-00001"> </li>
              <li><strong>IMEI</strong> <input id="qr-imei" class="input-qr" onchange={generateStick} type="text" value="{device._id}"></li>
              <li><strong>ID</strong> <input id="qr-id" class="input-qr" onchange={generateStick} type="text" value="{device.name}"></li>
              <li><strong>FV</strong> <input id="qr-fv" class="input-qr" onchange={generateStick} type="text" value="{device.status.version}"></li>
            </ul>
            <canvas id="qrcode"></canvas>
          </div>

          <div class="col-md-4 sticker-right">
            <div class="row">
              <div class="col-md-12 big-name text-center">{device.name}</div>
              <div class="col-md-12 small-name text-center">{device.name}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <script>

    import {fetch} from '../utils/utils'
    const QRCode = require('qrcode')

    this.deviceId = this.opts._id
    this.device = null

    this.loadDevice = () => {
      fetch({
        endpoint: `/v1/devices/${this.deviceId}`,
        update: false,
        callback: (data) => {
          this.device = data || false
          this.generateStick()
        }
      })()
    }

    this.generateStick = () => {
      if (!this.device) return window.swal('Stick generator failed')
      this.update()

      const pn = $(this.root).find('#qr-pn').val()
      const imei = $(this.root).find('#qr-imei').val()
      const id = $(this.root).find('#qr-id').val()
      const fv = $(this.root).find('#qr-fv').val()

      const text = `${pn}\n${imei}\n${id}\n${fv}`;

      const qrOptions = {
        scale: 2,
        margin: 0
      }

      QRCode.toCanvas(document.getElementById('qrcode'), text, qrOptions, function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
    }

    this.on('mount', () => {
      this.loadDevice()
      window.jQuery('body').addClass('no-padding')
    })

    this.on('unmount', () => {
      window.jQuery('body').removeClass('no-padding')
    })

  </script>

  <style>
    @media print {
      * {
        margin: 0;
        padding: 0;
      }

      @page {
        size: 62mm 22mm;
      }

      .container-fluid-header {display: none}
      .list-unstyled {
        margin-top: 5px !important;
        margin-left: 10px !important;
      }
    }

    .sticker {
      background-color: #eee;
      padding: 1mm;
    }

    ul {font-size: 8px; width: 35mm; margin-top: 5px;}

    canvas {
      width: 15mm;
      height: 15mm;
    }

    .input-qr {
      border: none;
    }

    .sticker-left {
      float:left;
      position: relative;
    }

    .sticker-right {
      float:right;
      border-left: 1px dashed #a7a7a7;
      width: 18mm;
    }

    .col-md-12, .col-md-8 {
      padding: 0 !important;
    }

    .small-name {
      font-size: 8px;
      margin-top: 2mm;
    }

    .big-name {
      margin-top: 4mm;
      font-size: 18px;
      font-weight: bold;
      height: 10mm;
      border-bottom: 1px dashed #a7a7a7;
    }

    #qrcode {
      position: absolute;
      top: 25px;
      left: 60px;
    }
  </style>
</sticks>
