<providers-edit>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <button class="btn btn-primary" onclick={save}>
        <span class="fa fa-save"></span> Save</button>
      <a href="/providers" class="btn btn-default" onclick={cancel}>
        <span class="fa fa-remove-circle"></span> Cancel</a>
    </div>
    <h1>Edit Provider</h1>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-md-offset-4">
        <form data-toggle="validator" class="form" autocomplete="off">
          <div class="form-group">
            <label for="name">name</label>
            <input class="form-control" id="name" value="{opts.name}"
              type="text"
              required>
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="apiurl">apiUrl</label>
            <input class="form-control" id="apiurl" value="{opts.auth.apiurl}" type="text">
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="clientId">clientId</label>
            <input class="form-control" id="clientId" value="{opts.auth.clientId}" type="text">
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="uname">username</label>
            <input class="form-control" id="uname" autocomplete="off" value="{opts.auth.username}" type="text">
            <div class="help-block with-errors"></div>
          </div>

          <div class="form-group">
            <label for="psw">password</label>
            <input class="form-control" id="psw" autocomplete="off" value="{opts.auth.password}"
              type="password"
              data-minlength="6">
            <div class="help-block with-errors">Current password is not visible,
              (not is transfered over the network). To cha</div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <script>
    var that = this;
    var utils = require('../utils/utils');
    opts.auth = {}

    this.save = () => {
      var data = this.getFormData()
      if (!data) return
      return console.log('return', data);

      var options = {
        url: window.__CONF.apiUrl + '/v1/providers/' + opts._id,
        update: false,
        data: data,
        type: 'PUT',
        callback: function(providers) {
          sweetAlert('Provider updated')
        },
        error: function() {
          utils.alertError()
        }
      }
      utils.fetch(options)()
    }

    this.loadProvider = () => {
      var options = {
        url: window.__CONF.apiUrl + '/v1/providers/' + this.opts._id,
        update: false,
        callback: function(data) {
          that.opts = data
          that.update()
        }
      }
      utils.fetch(options)()
    }

    this.getFormData = () => {
      var hasErrors = window.$('form').validator('validate').has('.has-error').length
      if (hasErrors) {
        return utils.formErrorsAlert()
      }

      var data = {
        name: window.$('#name').val().trim(),
        auth: {
          apiurl: window.$('#apiurl').val().trim(),
          clientId: window.$('#clientId').val().trim(),
          username: window.$('#uname').val().trim(),
        }
      }

      var password = window.$('#psw').val().trim()
      if (password) {
        data.auth.password = password
      }

      return data
    }

    this.on('mount', this.loadProvider)
  </script>
</providers-edit>
