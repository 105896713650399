<navbar-user>
  <ul class="nav navbar-nav">
    <li class="dropdown" data-module="devices">
      <a href="/devices"><span class="fa-fw fa fa-mobile"></span> Devices</a>
    </li>
    <li class="dropdown" data-module="accounts">
      <a href="/accounts"><span class="fa-fw fa fa-users"></span> Members</a>
    </li>
    <li class="dropdown" data-module="maps">
      <a href="/maps"><span class="fa-fw fa fa-map-marker"></span> Maps</a>
    </li>
    <li class="dropdown" data-module="invoices">
      <a href="/invoices"><span class="fa-fw fa fa-euro"></span> Invoices</a>
    </li>
  </ul>
</navbar-user>
