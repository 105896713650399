<accounts>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/accounts/0/add" class="btn btn-default">
        <span class="fa fa-plus-sign"></span> Add Account</a>
    </div>
    <h1>Accounts</h1>
  </div>

  <div class="container-fluid"><table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th data-orderable="false">
            <awesome-checkbox data={opts}></awesome-checkbox>
          <th data-sort="desc">id</th>
          <th>user</th>
          <th>email</th>
          <th>role</th>
          <th>freeze</th>
          <th data-orderable="false"></th>
        </tr>
      </thead>
      <thead class="filters">
        <tr>
          <td></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search"></td>
          <td class="has-search"></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search input-search-sm"></td>
          <td></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={opts.items} class="parent-showing">
          <td class="text-center">
            <awesome-checkbox data={opts}></awesome-checkbox>
          <td>{_id}</td>
          <td><strong><a href="/accounts/{_id}/edit">{username}</a></strong></td>
          <td>{email}</td>
          <td>{role}</td>
          <td>{(freeze) ? 'yes' : 'no'}</td>
          <td class="text-center">
            <div class="btn-group children-hidden">
              <a href="/accounts/{_id}/remove" class="btn btn-danger btn-xs">
                <span class="fa fa-trash"></span></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <script>
    const utils = require('../utils/utils');
    const fetchOptions = {
      opts: opts,
      url: window.__CONF.apiUrl + '/v1/accounts?select=*'
    }
    this.on('mount', utils.fetch(fetchOptions));

    const tableOptions = {order: [[3, 'asc']]}
    this.on('updated', utils.setupDatatables(tableOptions));
  </script>


</accounts>
