<device-tracklog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title">Tracklog
              <a nolink href="#"
                  data-toggle="popover"
                  data-title="Tracklog"
                  data-content="Select a date rage from/to to load the tracking data for this device. Use the dropdown menu on each input to select a fixed date (hours/days ago) or press the calendar icon to select any date."
                  data-placement="right"
                ><i class="fa fa-info-circle"></i></a>
            </h3>
          </div>
          <div class="panel-body">
            <form class="form-inline">
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-from'>
                  <input type='text' class="form-control width-auto" placeholder="from" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class='input-group input-group-sm date' id='date-to'>
                  <input type='text' class="form-control width-auto" placeholder="to" />
                  <span class="input-group-addon">
                    <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <input type='text' id="limit" class="form-control input-sm width-auto" placeholder="limit" size="5" value="100" />
              </div>
              <button onclick={loadData} type="button" class="btn btn-primary btn-sm" data-text-loading="wait...">Load</button>
            </form>

            <div class="row mt-2">
              <div if={data && data.length} id="tracking-data" class="col-md-7">
                <div class="pull-right" if={window.IS_ADMIN}>
                  <button onclick={toggleFilter} class="btn btn-default btn-xs">
                    <span class="fa fa-filter"></span> filtro</button>
                </div>
                <div>
                  <span class="label label-default">total: {dataStats.total}</span>
                  <span class="label label-default">with fix: {dataStats.totalFix}</span>
                </div>

                <div id="tracklog-filter" if={window.IS_ADMIN}>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="input-group input-group-sm">
                        <span class="input-group-addon" id="sizing-addon1">hdop</span>
                        <select id="filter-hdop" class="form-control">
                          <option value="">=</option>
                          <option value=">">&gt;=</option>
                          <option value="<">&lt;=</option>
                        </select>
                        <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon1">
                      </div>
                    </div>
                  </div>
                </div>

                <table class="table table-hover table-bordered table-condensed small mt-2">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Location</th>
                      <th>Speed</th>
                      <th>GPS</th>
                      <th>Satellites</th>
                      <th>GSM</th>
                      <th>Battery</th>
                      <th>Ext. Battery</th>
                      <th>Vsys5V</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr each={p in data} data-location="{p.data.loc[1]},{p.data.loc[0]}" onmouseover={setMarker} onclick={centerMarker} class="{window.IS_ADMIN ? p.classColor : false}">
                      <td if={p.gpstime > 0}>{window.moment(p.gpstime).format('DD-MM-YYYY HH:mm:ss')}</td>
                      <td if={p.gpstime <  1}>{window.moment(p.servertime).format('DD-MM-YYYY HH:mm:ss')}
                        <small style="font-size: 7px">(server)</small></td>
                      <td>{p.data.loc[1]}, {p.data.loc[0]}</td>
                      <td>{p.data.speed}</td>
                      <td>{p.data.gps}</td>
                      <td>{p.data.sats}</td>
                      <td>{p.data.gsm}</td>
                      <td>{p.data.battery}</td>
                      <td>{p.data.extbattery || 0}</td>
                      <td>{p.data.vsys || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-md-5" if={data && data.length}>
                <!-- -->
                <virtual if={dataStats.totalFix > 0}>
                  <div id="map-canvas" class="mb-3" style="min-height: 300px;"></div>
                  <p><small>only points with geolocation are displayed</small></p>
                </virtual>

                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" class="active"><a href="#tab-battery" aria-controls="tab-battery" role="tab" data-toggle="tab">Battery</a></li>
                  <li role="presentation"><a href="#tab-battery-ext" aria-controls="tab-battery-ext" role="tab" data-toggle="tab">Battery Ext.</a></li>
                  <li role<="presentation"><a href="#tab-gps" aria-controls="tab-gps" role="tab" data-toggle="tab">GPS</a></li>
                  <li role="presentation"><a href="#tab-altitude" aria-controls="tab-altitude" role="tab" data-toggle="tab">Altitude</a></li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                  <div role="tabpanel" class="tab-pane active" id="tab-battery">
                    <h4>Internal Battery Usage</h4>
                    <canvas class="charts" id="chart-battery"></canvas>
                  </div>
                  <div role="tabpanel" class="tab-pane" id="tab-battery-ext">
                    <h4>External Battery Usage</h4>
                    <canvas class="charts" id="chart-battery-ext"></canvas>
                  </div>
                  <div role="tabpanel" class="tab-pane" id="tab-gps">
                    <h4>GPS Signal Quality (HDOP)</h4>
                    <canvas class="charts" id="chart-gps"></canvas>
                    <p><small>only points with geolocation are displayed</small></p>
                  </div>
                  <div role="tabpanel" class="tab-pane" id="tab-altitude">
                    <h4>Altitude</h4>
                    <canvas class="charts" id="chart-alt"></canvas>
                    <p><small>only points with geolocation are displayed</small></p>
                  </div>
                </div>
              </div>

              <div class="col-md-12" if={data === null}>
                <p>Select a date range or press directly the load button</p>
              </div>

              <div class="col-md-12" if={data  && data.length === 0}>
                <p>No tracking data available. Try selecting other date range.</p>
              </div>
            </div>
          </div>
        </div> <!-- .panel.tracking -->
      </div>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'
    import {datetime2timestamp} from '../utils/utils'

    this.data = null
    this.device = this.parent.device || this.opts.device || {}

    this.dataStats = {
      total: 0,
      totalFix: 0
    }

    const charts = {
      'chart-battery': {
        datasets: [{
          label: 'millivolts',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-battery-ext': {
        datasets: [{
          label: 'millivolts',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-gps': {
        datasets: [{
          label: 'GPS',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      },
      'chart-alt': {
        datasets: [{
          label: 'Altitude',
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          type: 'line',
          fill: false,
          data: [],
        }]
      }
    }

    this.loadData = (e) => {
      e.preventUpdate = true

      const $button = window.$(e.target)
      $button.button('loading')

      const from = datetime2timestamp($('#date-from').data('date'))
      const to = datetime2timestamp($('#date-to').data('date'))
      let limit = parseInt(window.jQuery('#limit').val()) || 100

      fetch({
        endpoint: `/v1/devices/positions/${this.device._id}/?from=${from}&to=${to}&sort=servertime:desc&limit=${limit}`,
        update: false,
        type: 'get',
        callback: (data) => {
          $button.button('reset')
          this.data = data

          // reset charts and stats
          charts['chart-battery'].datasets[0].data = []
          charts['chart-battery-ext'].datasets[0].data.push
          charts['chart-gps'].datasets[0].data.push
          charts['chart-alt'].datasets[0].data.push
          this.dataStats.total = 0
          this.dataStats.totalFix = 0

          let i = 0
          let len = this.dataStats.total =  data.length
          let prev
          for (; i < len; i++) {
            const p = this.data[i]
            p.classColor = ''

            // battery
            charts['chart-battery'].datasets[0].data.push({x: p.servertime, y: p.data.battery})
            let extbattery = p.data.extbattery || p.data.extbatt || p.data.extra || 0
            charts['chart-battery-ext'].datasets[0].data.push({x: p.servertime, y: extbattery})

            // loc/gps
            if (p.data.loc[0] !== 0 && p.data.loc[1] !== 0) {
              this.dataStats.totalFix++;
              charts['chart-gps'].datasets[0].data.push({x: p.gpstime, y: p.data.gps})
              charts['chart-alt'].datasets[0].data.push({x: p.gpstime, y: p.data.alt})
            }

            // diferencia de tiempos entre posiciones (indican inicios de sesión)
            if (prev) {
              let diffTime = (prev.servertime - p.servertime) / 1000
              if (diffTime > 60) p.classColor = 'warning'
              if (diffTime > 300) p.classColor = 'danger'
            }

            prev = p
          }

          this.update();
        }
      })()
    }

    const renderChart = (canvasEl, label) => {
      let ctx = document.getElementById(canvasEl).getContext('2d')
      let cfg = getChartOptions(label, canvasEl)
      let chart = new Chart(ctx, cfg);
    }

    const setupDatepickers = () => {
      // setup dates
      const format = 'DD-MM-YYYY HH:mm:ss'
      const icons = {
        time: 'fa fa-time',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }

      $(this.root).find('#date-from').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-to').datetimepicker({format, icons, useCurrent: false})
      $(this.root).find('#date-from').data("DateTimePicker").maxDate(moment())
      $(this.root).find('#date-to').data("DateTimePicker").maxDate(moment())

      // link dates from/to
      $(this.root).find("#date-from").on("dp.change", function (e) {
        $('#date-to').data("DateTimePicker").minDate(e.date)
      })
      $(this.root).find("#date-to").on("dp.change", function (e) {
        $('#date-from').data("DateTimePicker").maxDate(e.date)
      })
    }

    const validateFromToDates = (from, to) => {
      let err = ''
      if (!from) err += 'From date is invalid. '
      if (!to) err += 'To date is invalid. '
      if (err != '') return swal(err)
    }

    const getChartOptions = (label, chart) => {
      return {
        type: 'line',
        data: {
          labels: [],
          datasets: charts[chart].datasets
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              type: "time",
              display: true,
              time: {
                displayFormats: {
                 'millisecond': 'MMM DD',
                 'second': 'MMM DD',
                 'minute': 'MMM DD',
                 'hour': 'MMM DD',
                 'day': 'MMM DD',
                 'week': 'MMM DD',
                 'month': 'MMM DD',
                 'quarter': 'MMM DD',
                 'year': 'MMM DD',
                }
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: label
              }
            }]
          }
        }
      }
    }

    const renderMap = () => {
      const MAPLINK = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
      const LATLNG = [0, 0]
      const ZOOM = 18
      const DEBUG = false

      if (!this.map) {
        this.map = L.map('map-canvas').setView(LATLNG, 13)

        L.tileLayer(
          '//cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
          attribution: '&copy; ' + MAPLINK + ' Contributors',
          maxZoom: 20,
        }).addTo(this.map)

        this.marker = L.marker([0, 0], {
          icon: L.icon({
            iconUrl: '/images/marker.png',
            iconSize: [24, 24],
            iconAnchor: [12, 20]
          })
        }).addTo(this.map);
      }

      // polyline
      const latlngs = []

      // buscamos los puntos del tracklog que contienen información de Geolocalización
      let i = 0
      let len = this.data.length
      for (; i < len; i++) {
        const point = this.data[i]
        if (point.data.loc[0] !== 0, point.data.loc[1] !== 0) {
          latlngs.push(point.data.loc.reverse())
        }
      }

      if (!latlngs.length) {
        this.marker.setOpacity(0)
        // no se ha cargado ningún punto con información de geolocalización,
        // no podemos renderizar un polyline, salimos...
        return
      }

      if (!this.polyline) {
        // si es la primera vez que se carga un tracklog creamos el polyline...
        this.polyline = L.polyline(latlngs, {color: 'red'}).addTo(this.map);
      } else {
        // ...si no lo actualizamos
        this.polyline.setLatLngs(latlngs)
      }

      // ajustamos el mapa al polyline
      this.map.fitBounds(this.polyline.getBounds());
    }

    this.setMarker = (e) => {
      e.preventUpdate = true
      // aunque el evento mouseover está sobre el <tr> riotjs nos devuelve en
      // el evento `e` un child td, para corregir esto utilizamos `parent()`
      const $tr = window.$(e.target).parent()
      const location = $tr.data('location').split(',')
      this.marker.setLatLng(location)
    }

    this.on('updated', () => {
      setupDatepickers()
      if (!this.data || !this.data.length) return
      renderChart('chart-battery', 'Battery mv')
      renderChart('chart-battery-ext', 'Battery Ext. mv')
      renderChart('chart-gps', 'GPS Signal (HDOP)')
      renderChart('chart-alt', 'Altitude')
      if (this.dataStats.totalFix > 0) {
        renderMap()
      }
    })

    this.on('mount', () => setupDatepickers())
  </script>

  <style type="text/css">
    .width-auto {
      width: auto !important;
    }

    .input-group-sm, .input-sm {
      display: block;
    }

    .charts {
      width: 100%;
      height: 120px;
    }

    #map-canvas {
      width: 100%;
      height: 300px;
    }

    #tracking-data {
      max-height: 900px;
      overflow: auto;
    }

    td {
      word-break: break-all;
    }
  </style>
</device-tracklog>
