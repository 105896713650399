export const admin = {
  home: require('./modules/home/home.tag'),

  sims: require('./modules/sims-admin/sims.tag'),
  simsadd: require('./modules/sims-admin/sims-add.tag'),
  simsedit: require('./modules/sims-admin/sims-edit.tag'),
  simsremove: require('./modules/sims-admin/sims-remove.tag'),

  maps: require('./modules/maps/maps.tag'),
  mapsadd: require('./modules/maps/maps-add.tag'),
  mapsedit: require('./modules/maps/maps-edit.tag'),
  mapsremove: require('./modules/maps/maps-remove.tag'),
  mapdevices: require('./modules/maps/maps-edit-devices.tag'),
  mapmeta: require('./modules/maps/maps-edit-meta.tag'),
  maplayers: require('./modules/maps/maps-edit-layers.tag'),
  mapcategories: require('./modules/maps/maps-edit-categories.tag'),
  mapmembers: require('./modules/maps/maps-edit-members.tag'),
  mapmanagecategories: require('./modules/maps/maps-manage-categories.tag'),

  device: require('./modules/devices/device.tag'),
  devices: require('./modules/devices/devices.tag'),
  devicesadd: require('./modules/devices-admin/devices-add.tag'),
  devicesedit: require('./modules/devices-admin/devices-admin-edit.tag'),
  devicesremove: require('./modules/devices-admin/devices-remove.tag'),

  accounts: require('./modules/accounts-admin/accounts.tag'),
  accountsown: require('./modules/accounts-admin/accounts-own.tag'),
  accountsadd: require('./modules/accounts-user/accounts-add.tag'),
  accountsedit: require('./modules/accounts-user/accounts-edit.tag'),
  accountsremove: require('./modules/accounts-admin/accounts-remove.tag'),

  providers: require('./modules/providers/providers.tag'),
  providersadd: require('./modules/providers/providers-add.tag'),
  providersedit: require('./modules/providers/providers-edit.tag'),
  providersremove: require('./modules/providers/providers-remove.tag'),

  sticker: require('./modules/stickers/sticker.tag'),

  logout: require('./modules/logout/logout.tag'),
  err404: require('./modules/errors/404.tag'),
  err502: require('./modules/errors/502.tag')
}

export const user = {
  home: require('./modules/home/home.tag'),

  sims: require('./modules/sims-user/sims.tag'),
  simsview: require('./modules/sims-user/sims-view.tag'),

  maps: require('./modules/maps/maps.tag'),
  mapsadd: require('./modules/maps/maps-add.tag'),
  mapsedit: require('./modules/maps/maps-edit.tag'),
  mapsremove: require('./modules/maps/maps-remove.tag'),
  mapdevices: require('./modules/maps/maps-edit-devices.tag'),
  mapmeta: require('./modules/maps/maps-edit-meta.tag'),

  device: require('./modules/devices/device.tag'),
  devices: require('./modules/devices/devices.tag'),
  devicesedit: require('./modules/devices/devices-edit.tag'),

  accounts: require('./modules/accounts-user/accounts.tag'),
  accountsown: require('./modules/accounts-admin/accounts-own.tag'),
  accountsadd: require('./modules/accounts-user/accounts-add.tag'),
  accountsedit: require('./modules/accounts-user/accounts-edit.tag'),
  accountsremove: require('./modules/accounts-user/accounts-remove.tag'),

  'demo-eskaymob': require('./modules/demos/demo-eskaymob.tag'),

  invoices: require('./modules/invoices/invoices.tag'),

  logout: require('./modules/logout/logout.tag'),
  err404: require('./modules/errors/404.tag'),
  err502: require('./modules/errors/502.tag')
}

export const all = {
  'awesome-checkbox': require('./modules/utils/checkbox.tag')
}

export default {
  admin,
  user,
  all
}
