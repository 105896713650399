<accounts-remove>
  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/accounts" class="btn btn-default" data-toggle="tooltip" title="Go back">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1 if={!this.account}>Remove Account {opts._id} (loading data...)</h1>
    <h1 if={this.account}>Remove Account {account.username}</h1>
  </div>

  <div if={this.account} class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-md-offset-4">
        <h4>Account details:</h4>
        <div id="formatted" class="thumbnail control-group"></div>
      </div>

      <div class="col-md-4 col-md-offset-4">
        <h4>Click to remove:</h4>
        <button class="btn btn-danger btn-block btn-lg" onclick={remove}>Remove account<br />_id: {opts._id}</button>
      </div>
    </div>
  </div>

  <script>
    import {fetch} from '../utils/utils'
    this.account = null
    import JSONFormatter from 'json-formatter-js'

    this.loadAccount = () => {
      fetch({
        endpoint: `/v1/accounts/${this.opts._id}`,
        update: false,
        callback: (data) => {
          this.account = data
          var dataString = ''
          try {
            const jsonFormatter = new JSONFormatter(data)
            dataString = jsonFormatter.render()
          } catch(e) {
            dataString = 'Sorry, error loading account data...'
          }

          console.log('typeof', typeof dataString)

          this.update()
          window.$(this.root).find('#formatted').html(dataString)
        }
      })()
    }

    this.remove = (e) => {
      e.preventUpdate = true
      fetch({
        endpoint: `/v1/accounts/${this.opts._id}`,
        update: false,
        type: 'delete',
        callback: (data) => {
          window.swal({
            title: 'Account removed',
            type: 'success',
            showCancelButton: false,
            confirmButtonClass: 'btn-primary',
            confirmButtonText: 'Ok, go back',
            closeOnConfirm: true
          },
          function() {
            document.location.href = '/accounts'
          })
        }
      })()
    }

    this.on('mount', this.loadAccount)
  </script>
</accounts-remove>
