<maps-remove>
  <div class="container-fluid">
    <div class="btn-group pull-right">
      <a href="/maps" class="btn btn-default" data-toggle="tooltip" title="Go back to maps list">
        <span class="fa fa-arrow-left"></span> Cancel</a>
    </div>
    <h1>Remove Map</h1>
  </div>

  <div class="container-fluid">
    <div if={!opts.removed} class="row">
      <div class="col-md-4 col-md-offset-4">
        <div id="map-data" class="thumbnail control-group">
          <p>loading map data for preview...</p>
        </div>
      </div>

      <div class="col-md-4 col-md-offset-4">
        <button class="btn btn-danger btn-block btn-lg" onclick={remove}>Remove map<br />{opts.title}</button>
      </div>
    </div>

    <div if={opts.removed} class="row">
      <div class="col-md-4 col-md-offset-4">
        <div class="alert alert-success fade in">
          Ok, bye bye map, work done!<br />
          Return to <a href="/maps" class="alert-link">map list</a></div>
      </div>
    </div>
  </div>

  <script>
    const that = this;
    const utils = require('../utils/utils');
    import JSONFormatter from 'json-formatter-js'

    this.remove = () => {
      console.log('oidiodid')
      utils.removeObject({id: opts._id, module: 'iot'}, function() {
        opts.removed = true;
        that.update();
      }, 'confirm!');
    }

    const fetchOptions = {
      type: 'get',
      update: false,
      url: window.__CONF.apiUrl + '/v1/iot/' + opts._id + '?select=title,_devices&populate=_devices',
      callback: function(data) {
        var dataString = '';
        try {
          const jsonFormatter = new JSONFormatter(data);
          dataString = jsonFormatter.render();
        } catch(e) {
          dataString = 'Sorry, error loading map data...';
        }
        jQuery('#map-data').html(dataString);
      }
    };

    utils.fetch(fetchOptions)();

  </script>
</maps-remove>
