<device>

  <virtual if={device}>
    <div class="container-fluid">
      <div class="row">
        <div class="container-fluid container-fluid-header">
          <a if={window.IS_ADMIN} href="/sticker/{device._id}" class="btn btn-default pull-right btn-sm mt-3">sticker</a>
          <h1 if={!device.customName} class="text-center">{device.name}</h1>
          <h1 if={device.customName} class="text-center">
            {device.customName} <small>{device.name}</small>
          </h1>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">
          <device-last-tracking if={device.hasOwnProperty('tracking')}></device-last-tracking>
          <device-last-sensing if={device.hasOwnProperty('sensing')}></device-last-sensing>
        </div>
        <div class="col-md-4">
          <device-status if={device.hasOwnProperty('tracking')}></device-status>
          <device-sim if={window.IS_ADMIN}></device-sim>
        </div>
        <div class="col-md-4">
          <device-metadata></device-metadata>
        </div>
      </div>
    </div>

    <device-tracklog if={device.hasOwnProperty('tracking')}></device-tracklog>
    <device-sensinglog if={device.hasOwnProperty('sensing')}></device-sensinglog>
  </virtual>

  <virtual if={device === null}>Loading device</virtual>
  <virtual if={device === false}>Fail loading device</virtual>

  <script>
    import {fetch} from '../utils/utils'

    // sub-tags
    this.deviceTracklogTag = require('./device-tracklog.tag')
    this.deviceSensinglogTag = require('./device-sensinglog.tag')
    this.LastTrackingTag = require('./device-last-tracking.tag')
    this.LastSensingTag = require('./device-last-sensing.tag')
    this.metadataTag = require('./device-metadata.tag')
    this.statusTag = require('./device-status.tag')
    this.simTag = require('./device-sim.tag')

    this.deviceId = this.opts._id
    this.device = null

    this.loadDevice = () => {
      let endpoint = `/v1/devices/${this.deviceId}?select=**`
      if (window.IS_ADMIN) {
        endpoint = `/v1/devices/${this.deviceId}?select=*&populate=_sim`
      }

      fetch({
        endpoint,
        update: false,
        callback: (data) => {
          this.device = data
          // if (window.IS_ADMIN) window.device = this.device
          this.device.alive = false
          this.update()
        }
      })()
    }

    this.save = (device) => {
      const data = device || this.device
      fetch({
        endpoint: '/v1/devices/' + this.device._id,
        data: data,
        type: 'put',
        update: false,
        callback: (data) => {
          window.swal('Device saved')
          this.update()
        }
      })()
    }

    this.on('mount', this.loadDevice)

  </script>

  <style type="text/css">
    .panel-body {
      overflow: auto;
    }
  </style>

</device>
