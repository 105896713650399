<maps-user>

  <div class="container-fluid container-fluid-header">
    <div class="btn-group pull-right">
      <a href="/maps/0/add" class="btn btn-default">
        <span class="fa fa-plus-sign"></span> Create Map</a>
    </div>
    <h1>Maps</h1>
  </div>

  <div class="container-fluid">
    <table class="table table-bordered table-hover" if={opts.items}>
      <thead>
        <tr>
          <th data-orderable="false">
            <awesome-checkbox data={opts}></awesome-checkbox>
          <th data-sort="desc">id</th>
          <th>title</th>
          <th>owner</th>
          <th>devices</th>
          <th>categories</th>
          <th data-orderable="false"></th>
        </tr>
      </thead>
      <thead class="filters">
        <tr>
          <td></td>
          <td class="has-search input-search-sm"></td>
          <td class="has-search"></td>
          <td class="has-search"></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody class="list">
        <tr each={opts.items} class="parent-showing" data-_id="{_id}">
          <td class="text-center">
            <awesome-checkbox data={this}></awesome-checkbox>
          <td>{_id}</td>
          <td><a href="/maps/{_id}/edit"><strong>{title}<strong></a></td>
          <td><a if={_account} href="/accounts/{_account._id}/edit">{_account.username}</a></td>
          <td>{_devices.length}</td>
          <td>{_categories.length}</td>
          <td class="text-center">
            <div class="btn-group children-hidden">
              <a href="/maps/{_id}/remove" class="btn btn-danger btn-xs" onclick={removeItem}>
                <span class="fa fa-remove"></span></a>
            </div>
          </td>
        </tr>

      </tbody>
    </table>

    <div class="text-center" if={!opts.items}>
      <td colspan="7" class="text-center"><p class="lead">
        Your maps list is empty. Ready to start?</p>
        <p><a href="/maps/0/add" class="btn btn-primary btn-lg">Start creating your first map</a></p></td>
    </div>
  </div>

  <script>
    const utils = require('../utils/utils');
    const query = [
      'limit=0',
      'select=*',
      'populate=_account:name;_devices:name;_categories:name',
      'search=type:tracking'
    ].join('&')

    const fetchOptions = {
      opts: opts,
      url: window.__CONF.apiUrl + '/v1/iot?' + query,
    }
    this.on('mount', utils.fetch(fetchOptions));
    const tableOptions = {order: [[3, 'asc']]}

    this.on('updated', utils.setupDatatables(tableOptions));

    this.removeItem = (e) => {
      e.preventUpdate = true;
      if (e.altKey && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        const $el = $(e.currentTarget);
        const _id = $el.closest('tr').data('_id');

        utils.removeMap(_id, function() {
          $('tr[data-_id="'+_id+'"]').fadeOut('slow', function() {
            $(this).remove();
          });
        });
      }
    }
  </script>
</maps-user>
