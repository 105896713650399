export function setupDatatables (options) {
  options = window.jQuery.extend(options, {
    search: {regex: true},
    responsive: true,
    lengthMenu: [[25, 50, 100, 200, -1], [25, 50, 100, 200, 'All']],
    iDisplayLength: -1,
    language: {
      search: '_INPUT_',
      searchPlaceholder: 'Search...'
    },
    autoWidth: false
  })

  return function (_view) {
    const view = this || _view
    const $table = window.jQuery(view.root).find('table')

    // data-index para el header del filtro
    $table.find('.filters td').each((index, td) => {
      const $td = window.$(td)
      $td.attr('data-index', index)
    })

    // DataTable
    const table = $table.DataTable(options)

    // Apply the search
    table.columns().eq(0).each(function (colIdx) {
      const that = this

      window.$(document)
        .on('keyup change', '.filters td:eq(' + colIdx + ') input', function () {
          // en un caso normal utilizariamos la variable colIdx para establecer
          // el indice de la columna donde se va a buscar, pero el plugin colVis
          // modifica estos indices cuando alguna columna está oculta, por lo que
          // utilizamos un valor predefinido para cada columna. Este valor se
          // lee del tag `data-id` en el `<td>` del filtro
          let index = window.$(this).parent('td').data('index')
          that
            .column(index)
            .search(this.value, true)
            .draw()
        })
    })

    return table
  }
}

export function clearDatatableFilters (table) {
  table.columns().every((index, col) => table.column(index).search('').draw())
}

export function fetch (opts) {
  const auth = window.Cookies.get('m2mss-login')

  opts = window.jQuery.extend({
    opts: null,
    type: 'get',
    url: window.__CONF.apiUrl || '',
    update: true,
    data: null,
    endpoint: ''
  }, opts)

  return function () {
    const that = this

    window.jQuery.ajax({
      headers: {
        'Authorization': 'Basic ' + auth
      },
      url: opts.url + opts.endpoint,
      type: opts.type,
      dataType: 'json',
      data: opts.data,
      beforeSend: (jqXHR) => {
        if (opts.button) opts.button.button('loading')
      }
    })
      .done(function (data, data2, data3) {
        // esto done es feo y debe darsele muerte. En su lugar un callback
        // aunque se genere algo de boirlerplate.
        if (opts.opts) that.opts.items = data
        if (opts.update) that.update()
        if (opts.callback) opts.callback(data)
      })
      .fail(function () {
        if (opts.fail) opts.fail.apply(this, arguments)
      })
      .always(function () {
        if (opts.button) opts.button.button('reset')
        if (opts.always) opts.always.apply(this, arguments)
      })
  }
}

/**
 * Mostrar/Ocultar una alerta, generalmente para indicar un error.
 * Se utiliza el componente de `bootstrap alert` y se muestran en el bottom
 * de la pagina
 * @type {Object}
 */
export const messageAlert = {
  show: (text, options) => {
    options = window.jQuery.extend({
      type: 'danger',
      time: 5000
    }, options)

    const $alert = window.jQuery('#alerts-container').find('div.alert')

    $alert.find('.alert-text').html(text)
    $alert.attr('class', 'alert alert-' + options.type)

    for (let i = 0; i < 3; i++) {
      $alert.fadeTo('fast', 0.5).fadeTo('fast', 1.0)
    }

    if (options.time) {
      setTimeout(messageAlert.hide, options.time)
    }
  },

  hide: () => {
    const $alert = window.jQuery('#alerts-container').find('div.alert')
    $alert.fadeOut('fast')
  }
}

/**
 * Método genérico para eliminar un recurso de la api (sim, account, device...)
 *
 * @param  {Object}   options         Id y module son requeridos.
 * @param  {String}   options.id
 * @param  {String}   options.module
 * @param  {Boolean}  options.confirm Por defecto true, se solicita confirmación
 * @param  {Function} callback
 */
export const removeObject = (opts, callback) => {
  callback = callback || function () {}
  var options = window.$.extend({}, {
    id: null,
    confirm: true,
    module: ''
  }, opts)

  const fetchOptions = {
    url: window.__CONF.apiUrl + '/v1/' + options.module + '/' + options.id,
    type: 'delete',
    update: false,
    callback: function () {
      window.swal('Deleted!', 'Ok, deleted. Work done.', 'success')
      callback()
    },
    error: function () {
      window.swal('Ooops!!', 'ERROR, deletion failed, try again.', 'success')
    }
  }

  if (!options.confirm) {
    window.swal('Deleted!', 'This resource has been deleted.', 'success')
    return fetch(fetchOptions)()
  }

  window.swal({
    title: 'Are you sure?',
    text: 'Will be deleted permanently',
    type: 'warning',
    showCancelButton: true,
    confirmButtonClass: 'btn-danger',
    confirmButtonText: 'Yes, dead & destruction!',
    closeOnConfirm: false
  },
  function () {
    fetch(fetchOptions)()
  })
}

export const setupAjax = () => {
  window.$.ajaxSetup({
    error: (xhr) => {
      const res = xhr.responseJSON || {}

      switch (xhr.status) {
        case 502:
        case 0:
          messageAlert.show(`Unexpected Error 502.`)
          break

        case 400:
          messageAlert.show(`Validation Error. ${res.message || ''}`)
          break

        case 404:
          messageAlert.show(`Server returns a 404 error.`)
          break

        case 403:
          messageAlert.show(`Sorry, you don't have enougth
              permissions for this action.`)
          break

        case 401:
          messageAlert.show(`Sorry, you are not logged in.
              Please, go to
              <a href="/login.html" class="alert-link" islink>
              login page</a>.`)
          break
      }
    }
  })
}

/**
 * Se valida un ICCID comprobando unicamente el número de carácteres.
 * Existe una formula precisa para determinar si un ICCID es válido o no.
 * @param  {String}  iccid
 * @return {Boolean}
 */
export const parseICCID = (iccid) => {
  iccid = iccid.trim()
  if (iccid.length > 18 && iccid.length <= 22) {
    return iccid
  }
  return false
}

/**
 * Se incluyó para el botón copy de la URL en la tabla providers
 * @type {window}
 */
export const clipboard = new window.ClipboardJS('.btn-copy', {
  target: function (trigger) {
    return trigger
  }
})

export const slugify = (text) => {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(p, c =>
        b.charAt(a.indexOf(c)))     // Replace special chars
    .replace(/&/g, '-and-')         // Replace & with 'and'
    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
    .replace(/--+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')             // Trim - from end of text
}

export function activateMenuOption (module) {
  window.$('.nav-sidebar').find('a').removeClass('active')
  window.$('.nav-sidebar')
    .find('li[data-module="' + module + '"]')
    .find('a')
    .addClass('active')
}

export function formErrorsAlert (title, desc, type) {
  title = title || 'Oops!'
  desc = desc || 'There are errors in the form. Please correct them to continue.'
  type = type || 'error'
  window.sweetAlert(title, desc, type)
}

export function errorAlert (title, desc, type) {
  title = title || 'Oops!'
  desc = desc || 'Error, try again.'
  type = type || 'error'
  window.sweetAlert(title, desc, type)
}

/**
 * Guarda el estado de la ultima pestañana abierta en un tab.
 * Se debe llamar a este método desde el evento mount del tag:
 *
 * @example
 * this.on('mount', utils.tabsStatus(this, 'maps'))
 *
 * @param  {Object} root        Objeto `this` del tag
 * @param  {String} sectionName Nombre de la sección, (cualquier cadena)
 */
export function tabsStatus (root, sectionName) {
  const tab = `${sectionName}:tab`
  window.$(root.root).find('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
    const $tab = window.$(e.target)
    const href = $tab.attr('href')
    window.Lockr.set(tab, href)
  })

  const selectTab = window.Lockr.get(tab)
  if (selectTab) {
    window.$(root.root).find('.nav-tabs a[href="' + selectTab + '"]').tab('show')
  }
}

export function datetime2timestamp (stringdatetime) {
  try {
    const datetime = stringdatetime.split(' ')
    const date = datetime[0].split('-')
    const time = datetime[1].split(':')

    const timestamp = window.moment(new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2])).utc()
    return timestamp.unix() * 1000
  } catch (err) {
    return false
  }
}

// init dom
window.jQuery(($) => {
  const $body = $('body')

  $body.tooltip({
    selector: '[data-toggle="tooltip"]'
  })

  $body.popover({
    selector: '[data-toggle="popover"]',
    container: 'body'
  })

  // ocultar popover al hacer click en cualquier sitio
  $(document).on('click', function (e) {
    $('[data-toggle="popover"],[data-original-title]').each(function () {
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $('.popover').has(e.target).length === 0
      ) {
        (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false  // fix for BS 3.3.6
      }
    })
  })

  // fix filter header datatables
  $('tr.fitlers').on('click', 'th', (e) => e.preventDefault())

  $('.close').on('click', (e) => {
    e.preventDefault()
    messageAlert.hide()
  })

  $(document).on('click', '[islink]', function (e) {
    e.preventDefault()
    e.stopPropagation()
    const href = $(this).attr('href')
    document.location.href = href
  })

  $(document).on('click', '[nolink]', function (e) {
    e.preventDefault()
  })

  $(document).on('click', '.vtabs-menu li', function (e) {
    const $item = $(this)
    const itemName = $item.data('toggle')
    const $menu = $(this).closest('.vtabs-menu')
    const $container = $menu.next('.vtabs-container')

    $menu.find('li').removeClass('active')
    $item.addClass('active')

    $container.find('.tab-pane').removeClass('active')
    $container.find('#' + itemName).addClass('active')
  })
})
