<maps-edit-meta>
  <div class="panel panel-primary">
    <div class="panel-heading"></div>
    <div class="panel-body">
      <div class="form-group">
        <label for="title" class="col-md-2 control-label">Map Title</label>
        <div class="col-md-10">
          <input type="text" class="form-control" id="title" required value={opts.map.title}>
        </div>
      </div>

      <div class="form-group">
        <label for="slug" class="col-md-2 control-label">Slug</label>
        <div class="col-md-10">
          <input type="text" class="form-control" required id="slug" value={opts.map.slug}>
        </div>
      </div>

      <div class="form-group">
        <label for="public" class="col-md-2 control-label">Private</label>
        <div class="col-md-10">
          <input type="checkbox" class="checkbox" id="private" checked={opts.map.private} value="1">
        </div>
      </div>

      <div class="form-group">
        <label for="lat" class="col-md-2 control-label">Location (lat, lon)</label>
        <div class="col-md-5">
          <input type="text" pattern="^[0-9-?][0-9.]*[0-9]$" class="form-control" id="lat" required value={opts.map.conf.loc[1]} onkeyup={this.setLat}>
        </div>
        <div class="col-md-5">
          <input type="text" pattern="^[0-9-?][0-9.]*[0-9]$" class="form-control" id="lon" required value={opts.map.conf.loc[0]} onkeyup={this.setLon}>
        </div>
      </div>

      <div class="form-group mt-2">
        <label for="zoom" class="col-md-2 control-label">Zoom</label>
        <div class="col-md-10">
          <input type="number" min="1" max="20" class="form-control" required id="zoom" value={opts.map.conf.zoom} onkeyup={this.setZoom}>
        </div>
      </div>

    </div>
  </div>

  <style>
    .form-group {
      overflow: auto;
    }
  </style>
</maps-edit-meta>
